import React, {useState} from 'react';
import PanelContent from "../../../components/adminpanel/PanelContent";
import {useTranslation} from "react-i18next";
import {showModal} from "../../../app/store/modalReducer";
import AutoCloseableModalWithTitle from "../../../components/common/ui/modal/AutoCloseableModalWithTitle";
import {useDispatch, useSelector} from "react-redux";
import {resetDictionaryCache} from "../../../service/cacheService";
import OrangeButtonFilled from "../../../components/common/ui/buttons/OrangeButtonFilled";
import Spinner from "../../../components/common/Spinner";

/**
 * Раздел "служебная информация" в админке
 */
const ServiceInfo = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const token = useSelector(state => state.userReducer.token);
    const [loading, setIsLoading] = useState(false);

    function showPopupMessage(text) {
        dispatch(showModal({
            popup: {
                title: "",
                body: <AutoCloseableModalWithTitle text={text} delay={5000}/>
            }
        }));
    }

    function resetDictCache() {
        setIsLoading(true);
        const resp = resetDictionaryCache(token);
        resp.then((resp) => {
            setIsLoading(false);
            showPopupMessage(resp.data.data)
        }, (err) => {
            showPopupMessage(err.response?.data?.messages ?
                err.response?.data?.messages?.ERROR[0] : err.message)
        })
        setIsLoading(false)
    }


    return (
        <PanelContent title={t('menu.panel.serviceInfo')}>
            {loading ? <Spinner/> :
                <OrangeButtonFilled
                    onClick={() => resetDictCache()}>
                    {t("button.resetDictionaryCache")}
                </OrangeButtonFilled>
            }
        </PanelContent>
    );
};

export default ServiceInfo;