import React from 'react';
import PanelContent from "../../../components/adminpanel/PanelContent";
import UsersTable from "./table/UsersTable";

/**
 * Сводка по пользователям
 */
const UsersPanel = () => {
    return (
        <PanelContent title='Пользователи'>
            <UsersTable/>
        </PanelContent>
    );
};

export default UsersPanel;