import React from 'react';
import TableLine from "../simple/line/TableLine";
import TableCell from "../simple/cells/TableCell";

/**
 * Гибкий компонент - строки таблицы
 */
const TableLines = ({entities, fields}) => {
    return (
        <>
            {
                entities.map((entity, index) => (
                    <TableLine key={index}>
                        {
                            fields.map((field, idx) => (
                                <TableCell key={idx} cellSize={field.size} additionalStyle='small-text'>
                                    <span>
                                        {
                                            field.extraFunction === null || field.extraFunction === undefined ?
                                                entity[field.id] :
                                                field.extraFunction(entity[field.id])
                                        }
                                    </span>
                                </TableCell>
                            ))
                        }
                    </TableLine>
                ))
            }
        </>
    );
};

export default TableLines;