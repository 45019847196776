/**
 * Хэлпер в котором перечислены функции, использующиеся при отрисовке полей таблиц
 */

import {EMPTY} from "../../../../app/const/appConst";

/**
 * Представить массив в формате:
 * [element1, element2, element3, ...]
 */
export function getArrayElementAsStringWithBracketsAndDelimiter(arrayElement) {
    return `[${arrayElement.join(", ")}]`
}

export function getBooleanAsYesOrEmpty(boolValue) {
    return boolValue ? 'ДА' : EMPTY;
}