import React from 'react';
import {useTranslation} from "react-i18next";
import {getArrayElementAsStringWithBracketsAndDelimiter, getBooleanAsYesOrEmpty} from "../../../../components/common/table/abstracttable/tableHelper";
import {getDatePrettyFormat} from "../../../../app/helper/dateHelper";
import Table from "../../../../components/common/table/abstracttable/Table";

/**
 * Таблица с пользователями
 */
const UsersTable = () => {
    const {t} = useTranslation();
    const userTableMetadata =
        {
            entity: 'users',
            fields:
                [
                    {id: 'lastName', name: t('common.user.lastname'), size: 20},
                    {id: 'firstName', name: t('common.user.name'), size: 20},
                    {id: 'email', name: t('common.user.email'), size: 20},
                    {
                        id: 'roles',
                        name: t('common.user.roles'),
                        size: 20,
                        extraFunction: getArrayElementAsStringWithBracketsAndDelimiter
                    },
                    {
                        id: 'lastLogin',
                        name: t('common.user.lastLogin'),
                        size: 10,
                        extraFunction: getDatePrettyFormat
                    },
                    {
                        id: 'active',
                        name: t('common.user.isActive'),
                        size: 10,
                        extraFunction: getBooleanAsYesOrEmpty
                    }
                ],
            needPagination: true
        };
    return (
        <Table entityMetadata={userTableMetadata}/>
    );
};

export default UsersTable;