import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {setBurgerActive} from "../../../../../app/store/burgerReducer";
import {clearUserData} from "../../../../../utils/userutils";
import MobileLinksBlock from "../MobileLinksBlock";
import SectionTitle from "./SectionTitle";
import MobileSectionLinks from "../MobileSectionLinks";
import MobileSectionLink from "../MobileSectionLink";
import {hasValue} from "../../../../../app/helper/commonHelper";
import {faUser} from "@fortawesome/free-solid-svg-icons/faUser";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDragon} from "@fortawesome/free-solid-svg-icons/faDragon";
import {useNavigate} from "react-router-dom";
import {showModal} from "../../../../../app/store/modalReducer";
import UserPetsTable from "../../../table/userpets/UserPetsTable";

/**
 * Меню мобильной версии для авторизованного пользователя
 */
const MobileMenuAuthUser = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const firstName = useSelector(state => state.userReducer.firstName);
    const activePet = useSelector(state => state.userReducer.activePet);
    const userPets = useSelector(state => state.userReducer.pets);
    const [isUserInfoActive, setUserInfoActive] = useState(false);
    const [isUserPetsActive, setUserPetsActive] = useState(false);

    function closeMobileMenu() {
        dispatch(setBurgerActive(false))
    }

    /*function showLanguageSelectorPopup() {
        dispatch(showModal({
            popup: {
                title: t("common.chooseLanguage"),
                body: <LanguageSelector/>
            }
        }))
    }*/

    function onClickExitActions() {
        clearUserData(dispatch);
        closeMobileMenu();
    }

    function onClickActivePetActions() {
        navigate(`/pets/${activePet.id}`);
        closeMobileMenu();
    }

    function onClickProfileActions() {
        navigate(`/profile/`);
        closeMobileMenu();
    }

    function onClickAddPetActions() {
        navigate('/pets/new-step-one');
        closeMobileMenu();
    }

    function onClickOtherUserPetsActions() {
        dispatch(showModal({
            popup: {
                title: 'Питомцы',
                body: <UserPetsTable/>
            }
        }))
    }

    return (
        <>
            <MobileLinksBlock>
                <SectionTitle isActive={isUserInfoActive} setActive={setUserInfoActive} title={firstName}
                              iconBefore={<FontAwesomeIcon icon={faUser} className='linkIcon'/>}/>
                <MobileSectionLinks isActive={isUserInfoActive}>
                    <MobileSectionLink title={t("page.profile.title")} onClickAction={onClickProfileActions}/>
                    <MobileSectionLink title={t("button.logout")} onClickAction={onClickExitActions}/>
                </MobileSectionLinks>
            </MobileLinksBlock>
            <MobileLinksBlock>
                <SectionTitle isActive={isUserPetsActive} setActive={setUserPetsActive} title={t("labels.pets")}
                              iconBefore={<FontAwesomeIcon icon={faDragon} className='linkIcon'/>}/>
                <MobileSectionLinks isActive={isUserPetsActive}>
                    {hasValue(activePet) ?
                        <MobileSectionLink title={activePet.name} onClickAction={onClickActivePetActions}/> : null}
                    {hasValue(userPets) && userPets.length > 1 ?
                        <MobileSectionLink title={t("button.chooseAnother")}
                                           onClickAction={onClickOtherUserPetsActions}/> : null}
                    <MobileSectionLink title={t("button.add")} onClickAction={onClickAddPetActions}/>
                </MobileSectionLinks>
            </MobileLinksBlock>
        </>
    );
};

export default MobileMenuAuthUser;