import React from 'react';

/**
 * Компонент ошибки формы
 * */
const FormError = ({title, additionalStyle= ''}) => {

    if (title === null || title === '') {
        return null;
    }
    return (
        <div className={`form__error ${additionalStyle}`}>
            <span>{title}</span>
        </div>
    );
};

export default FormError;