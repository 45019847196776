import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {validateNameRegEx} from "../../../../../utils/stringutils";
import UserService from "../../../../../service/UserService";
import FormError from "../../parts/inputs/wrappers/FormError";
import Spinner from "../../../Spinner";
import {useDispatch} from "react-redux";
import {showModal} from "../../../../../app/store/modalReducer";
import {getStoredItem, isFieldFilled, showSuccessMessagePopup, storeItem} from "../../helper/formHelper";
import Form from "../../abstract/Form";
import {
    EMAIL,
    FIRST_NAME,
    LAST_NAME,
    PASSWORD,
    REPEAT_PASSWORD,
    USER_AGREEMENT
} from "../../helper/commonFormConstants";
import TextInputFormItem from "../../parts/inputs/wrappers/TextInputFormItem";
import CheckboxFormItem from "../../parts/inputs/wrappers/CheckboxFormItem";
import PasswordFormItem from "../../parts/inputs/wrappers/PasswordFormItem";
import {NUMBER, STRING} from "../../helper/formConstants";

/**
 * Форма регистрации пользователя
 */
const UserRegisterForm = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const [loading, setIsLoading] = useState(false);

    const schema = yup.object().shape({
        firstName: yup.string().required(t("form.common.errors.fieldRequired")).matches(validateNameRegEx),
        lastName: yup.string().required(t("form.common.errors.fieldRequired")).matches(validateNameRegEx),
        email: yup.string().email(t("form.common.errors.emailIncorrectFormat"))
            .required(t("form.common.errors.fieldRequired")),
        // phoneNumber: yup.string().required(),
        password: yup.string().min(8, t("form.common.errors.minEightSymbols"))
            .required(t("form.common.errors.fieldRequired")),
        repeatPassword: yup.string()
            .oneOf([yup.ref("password"), null], t("form.common.errors.passMustBeTheSame")),
        agreement: yup.bool().oneOf([true], "You must accept the terms and conditions")
    })
    const {register, handleSubmit, formState: {errors}, setValue, getValues} = useForm({
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        fillStoredFields();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function fillStoredFields() {
        setValue(FIRST_NAME, getStoredItem(FIRST_NAME, STRING))
        setValue(LAST_NAME, getStoredItem(LAST_NAME, STRING))
        setValue(EMAIL, getStoredItem(EMAIL, STRING))
    }

    function submitForm(data) {
        setIsLoading(true)

        const response = UserService.registerUser(data);
        response.then(() => {
            setError(false);
            setIsLoading(false)
            showSuccessMessagePopup(dispatch, showModal,
                t("form.registration.success"),
                t("form.registration.activationMessage"));
        }, (error) => {
            setError(true)
            setErrorMessage(error.response?.data?.messages ?
                error.response?.data?.messages?.ERROR[0] : error.message)
            setIsLoading(false)
        });
        //послать запрос на сервер для регистрации пользователя
        // при успехе очистить данные из стора
    }

    return (
        <Form submitForm={submitForm} handleSubmit={handleSubmit}>
            {/*Имя*/}
            <TextInputFormItem errors={errors} fieldName={FIRST_NAME} placeholder={t("form.registration.firstName")}
                               hasValue={isFieldFilled(getValues, FIRST_NAME)} register={register}
                               onBlurFunction={storeItem} tabIndex='1'/>
            {/*Фамилия*/}
            <TextInputFormItem errors={errors} fieldName={LAST_NAME} placeholder={t("form.registration.lastName")}
                               hasValue={isFieldFilled(getValues, LAST_NAME)} register={register}
                               onBlurFunction={storeItem} tabIndex='2'/>

            {/*Имейл*/}
            <TextInputFormItem errors={errors} fieldName={EMAIL} placeholder={t("form.registration.email")}
                               hasValue={isFieldFilled(getValues, EMAIL)} register={register}
                               onBlurFunction={storeItem} tabIndex='3'/>
            {/*Локация*/}
            {/*<div className="form-item">
                        <input type="text"
                               className="form__input _req"
                               id="formLocation"
                               name="location"
                               placeholder={t("form.registration.location")}
                               tabIndex="4"/>
                    </div>*/}
            {/*Номер телефона*/}
            {/*<div className="form-item">
                        <input type="text"
                               className={errors.phoneNumber ? `${INPUT_CLASS} ${ERROR_CLASS}` : `${INPUT_CLASS}`}
                               id="formPhoneNumber"
                               {...register("phoneNumber")}
                               placeholder={t("form.registration.phoneNumber")}
                               autoComplete="off"
                               onBlur={(e) =>
                                   putValueToStore({phoneNumber: e.target.value}, errors.phoneNumber)}
                               tabIndex="5"/>
                    </div>*/}
            {/*Пароль*/}
            <PasswordFormItem fieldName={PASSWORD} register={register}
                              placeholder={t("form.registration.password")} tabIndex='6'
                              autocomplete="new-password" errors={errors}/>

            {/*Повторить пароль*/}
            <PasswordFormItem fieldName={REPEAT_PASSWORD} register={register}
                              placeholder={t("form.registration.repeatPassword")} tabIndex='7'
                              autocomplete="new-password" errors={errors}/>

            {/*Согласие на обработку*/}
            <CheckboxFormItem fieldName={USER_AGREEMENT} register={register} checkboxValue={false}
                              title={t("form.registration.consent")} tabIndex='8' errors={errors}/>

            {error ? <FormError title={errorMessage}/> : null}
            {loading ? <Spinner/> :
                <button type="submit"
                        className="form__button">{t("button.register")}</button>
            }
        </Form>
    );
};

export default UserRegisterForm;