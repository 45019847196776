import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import Content from "../../../../components/common/Content";
import Spinner from "../../../../components/common/Spinner";
import {useDispatch, useSelector} from "react-redux";
import {PET_BASIC_INFO_TAB, PET_EXTENDED_INFO_TAB} from "../petConst";
import PetExtendedInfo from "./pettabs/PetExtendedInfo";
import Breadcrumbs from "../../../../components/common/Breadcrumbs";
import PetBasicInfo from "./pettabs/PetBasicInfo";
import TableNoResults from "../../../../components/common/table/TableNoResults";
import SwitchableTwoTabs from "../../../../components/common/ui/tabs/SwitchableTwoTabs";
import {hasValue} from "../../../../app/helper/commonHelper";
import CircleTemplateForImage from "../../../../components/common/photo/CircleTemplateForImage";
import {updateActivePetWithBreed, userHasPet} from "../../newpet/helpers/petHelper";
import {getBreedInfo} from "../../../breeds/helpers/breedHelper";

/**
 * Страница с просмотром инфы о питомце
 */
const Pet = ({locationKey}) => {
    const [pet, setPet] = useState({})
    const [breed, setBreed] = useState({})
    const params = useParams();
    const token = useSelector(state => state.userReducer.token);
    const activePet = useSelector(state => state.userReducer.activePet);
    const pets = useSelector(state => state.userReducer.pets);
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState(PET_BASIC_INFO_TAB);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    function getPetInfo() {
        setIsLoading(true);
        //если мы хотим посмотреть инфо об активном питомце из стора, нам ни к чему делать повторный запрос
        if (hasValue(activePet) && activePet.id === Number(params.id)) {
            setPet(activePet);
            getBreedInfo(activePet?.breedId, setBreed, setErrorMessage);
        } else {
            //если не нашли запрашиваемого питомца в списке питомцев
            if (!userHasPet(pets, params.id)) {
                setErrorMessage("Питомец на найден");
                setIsLoading(false)
                return;
            }
            //если произошла валидная смена питомца, то мы запрашиваем все данные по нему
            updateActivePetWithBreed(setBreed, params.id, token, dispatch, setErrorMessage)
        }
        setIsLoading(false)
    }


    useEffect(() => {
        window.scrollTo({top: 0, left: 0});
        setErrorMessage("");
        getPetInfo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locationKey])

    return (
        <Content>
            {isLoading ? <Spinner/> :
                errorMessage !== "" ? <TableNoResults title={errorMessage}/> :
                    <>
                        <Breadcrumbs elements={[{page: pet.name, path: ''}]}/>
                        <CircleTemplateForImage image={activePet.photo} navigatePath={`/pets/${params.id}/photo`}/>
                        <SwitchableTwoTabs activeTab={activeTab} setActiveTab={setActiveTab}
                                           firstTabAlias={PET_BASIC_INFO_TAB} firstTabName='Основное'
                                           secondTabAlias={PET_EXTENDED_INFO_TAB} secondTabName='Дополнительное'/>
                        {activeTab === PET_BASIC_INFO_TAB ?
                            <PetBasicInfo pet={activePet} breed={breed}/> :
                            <PetExtendedInfo pet={activePet}/>}
                    </>}
        </Content>
    );
};

export default Pet;