import React from 'react';
import {isEmptyOrNull} from "../../../../../app/helper/commonHelper";
import {storeItem} from "../../helper/formHelper";

/**
 * Список radio-кнопок
 */
const RadioSet = ({register, fieldName, radioSetTitle, options, required, additionalClasses='', needToStore = false}) => {
    function doSelectLogic(fieldName, optionId) {
        if (!needToStore) {
            return;
        }
        storeItem(fieldName, optionId);
    }

    if (isEmptyOrNull(options)) {
        return null;
    }
    return (
        <fieldset className={`radio-fieldset ${additionalClasses}`}>
            <legend>{radioSetTitle}</legend>

            {options.map((option, index) => {
                return <div key={index} className="radio-option" onClick={() => doSelectLogic(fieldName, option.id)}>
                    <input type="radio" id={`${fieldName}__${option.id}`} value={option.id}
                           {...register(fieldName, {required: required})}/>
                    <label htmlFor={`${fieldName}__${option.id}`}>{option.name}</label>
                </div>;
            })}
        </fieldset>
    );
};

export default RadioSet;