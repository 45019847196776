import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import MobileMenuAuthUser from "./MobileMenuAuthUser";
import LoginButton from "./LoginButton";
import MobileSectionLink from "../MobileSectionLink";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPaw} from "@fortawesome/free-solid-svg-icons/faPaw";
import {useNavigate} from "react-router-dom";
import {setBurgerActive} from "../../../../../app/store/burgerReducer";
import TelegramLink from "../../../../../pages/index/TelegramLink";


/**
 * Компонент меню для мобильных устройств
 * */
const MobileMenu = () => {
    const isBurgerActive = useSelector(state => state.burgerReducer.isBurgerActive);
    const isUserAuth = useSelector(state => state.userReducer.isUserAuth);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    function closeMobileMenu() {
        dispatch(setBurgerActive(false))
    }

    function onClickBreedsActions() {
        navigate('/breeds');
        closeMobileMenu();
    }

    return (
        <div className={isBurgerActive ? 'mobile-section active' : 'mobile-section'}>
            <div className="mobile-section__wrapper">
                {isUserAuth ? <MobileMenuAuthUser/> : <LoginButton/>}
                <MobileSectionLink title='Породы'
                                   onClickAction={onClickBreedsActions}
                                   iconBefore={<FontAwesomeIcon icon={faPaw} className='linkIcon'/>}/>
                {isUserAuth ? <div className="additionalInfo"><TelegramLink/></div> : null}


            </div>
        </div>
    );
};

export default MobileMenu;