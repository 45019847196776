import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import {getActivePetForUpdatePetInfo} from "../../../newpet/helpers/petHelper";
import UpdatableElementPreview from "../../../../../components/common/UpdatableElementPreview";
import PetForm from "../../../newpet/components/forms/PetForm";
import {DEFAULT, EMPTY} from "../../../../../app/const/appConst";
import BreedService from "../../../../breeds/api/BreedService";
import {isEmptyOrNull} from "../../../../../app/helper/commonHelper";
import FormError from "../../../../../components/common/form/parts/inputs/wrappers/FormError";
import PetService from "../../../newpet/api/PetService";
import {updatePetBreed} from "../../../../../app/store/userReducer";
import FormSubmitButton from "../../../../../components/common/form/parts/inputs/FormSubmitButton";
import PartInfoContent from "../../../../../components/common/form/abstract/PartInfoContent";
import PetAnimalKindFormItem from "../../../common/components/form/PetAnimalKindFormItem";
import {PET_BREED} from "../../../newpet/helpers/petFormConstants";
import PetBreedFormItem from "../../../common/components/form/PetBreedFormItem";
import {removeItem} from "../../../../../components/common/form/helper/formHelper";

/**
 * Работа с породой питомца
 */
const PetBreed = () => {
    const {i18n, t} = useTranslation();
    const params = useParams();
    const dispatch = useDispatch();
    const activePet = useSelector(state => state.userReducer.activePet);
    const pets = useSelector(state => state.userReducer.pets);
    const token = useSelector(state => state.userReducer.token);
    const [errorMessage, setErrorMessage] = useState("");
    const [breedErrorMessage, setBreedErrorMessage] = useState("");
    const [breeds, setBreeds] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const [animalKind, setAnimalKind] = useState(DEFAULT);
    const {
        register,
        setValue,
        getValues,
        watch,
        formState: {errors},
        handleSubmit
    } = useForm({
        mode: "onBlur",
        reValidateMode: "onBlur"
    })
    //смотрим за изменением полей
    const allValues = watch();

    function clearErrors() {
        setErrorMessage("");
        setBreedErrorMessage("");
    }

    function isButtonActive() {
        return !isEmptyOrNull(getValues(PET_BREED)) && activePet.breedId !== getValues(PET_BREED);
    }

    /**
     * Просечиваем начальное значение.
     * Поскольку мы не сохраняем промежуточный выбор в стор, то ориентируемся только на начальное значение
     */
    function getBreedInitialId() {
        if (!isEmptyOrNull(activePet.breedId)) {
            return activePet.breedId;
        }
        return 0;
    }

    function init() {
        setBreedErrorMessage(EMPTY);
        if (isEmptyOrNull(activePet.breedId)) {
            setBreedErrorMessage('Произошла ошибка при определении породы питомца');
            return;
        }
        BreedService.getAnimalKindAllBreedsByExample(activePet.breedId, i18n.language)
            .then((resp) => {
                const data = resp.data.data;
                setAnimalKind(data.animalKind);

                const transformedBreeds = data.breedsViewDto.map(breedInfo => ({
                    id: breedInfo.breedId,
                    translation: {
                        title: breedInfo.title
                    }
                }));
                setBreeds(transformedBreeds);
                setBreedErrorMessage(EMPTY);
            }, (error) => {
                setErrorMessage(error.response?.data?.messages ?
                    error.response?.data?.messages?.ERROR[0] : error.message)
            });
    }

    /**
     * Чистим sessionStorage
     */
    function clearStoredValues() {
        removeItem(PET_BREED);
    }

    useEffect(() => {
        window.scrollTo({top: 0, left: 0});
        clearErrors();
        getActivePetForUpdatePetInfo(activePet.id, params.id, init, pets, setErrorMessage, token, dispatch);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**
     * Проверка активности кнопки сохранения формы
     */
    useEffect(() => {
        isButtonActive()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allValues])

    function submitForm(data) {
        if (!isButtonActive()) {
            return;
        }
        clearErrors();
        setIsLoading(true)
        const response = PetService.updatePetBreed(activePet.id, data.petBreed, token);
        response.then((resp) => {
            dispatch(updatePetBreed({
                breedId: resp.data.data
            }))
            clearStoredValues();
        }, (error) => {
            setBreedErrorMessage(error.response?.data?.messages ?
                error.response?.data?.messages?.ERROR[0] : error.message)
        });
        setIsLoading(false)
    }

    return (
        <PartInfoContent errorMessage={errorMessage}>
            <UpdatableElementPreview title={t('page.pet.breed')} backTo={`/pets/${params.id}`}
                                     content={t('page.pet.canEditBreed')}/>
            <PetForm handleSubmit={handleSubmit} submitForm={submitForm}>
                <PetAnimalKindFormItem animalKind={animalKind} disabled={true}/>
                <PetBreedFormItem breeds={breeds} register={register} setValue={setValue} errors={errors}
                                  tabIndex='1' initialId={getBreedInitialId()}/>

                <FormError title={breedErrorMessage}/>
                <FormSubmitButton title={t('button.save')} isLoading={isLoading} isButtonActive={isButtonActive}
                                  handleSubmit={handleSubmit} submitForm={submitForm}/>
            </PetForm>
        </PartInfoContent>
    );
};

export default PetBreed;