import React, {useState} from 'react';
import {EMPTY} from "../../../app/const/appConst";
import {isEmptyOrNull} from "../../../app/helper/commonHelper";
import {useTranslation} from "react-i18next";
import {getProgressBarColor} from "../../../components/common/progressbar/progressbarHelper";

/**
 * Блок с информацией о породе
 */
const BreedInfoBlock = ({data}) => {
    const {t} = useTranslation();
    const DETAILS = "Детали";
    const SHOW_LESS = "Свернуть";
    const LABEL_DELAY = 200;
    const [expanded, setExpanded] = useState(false);
    const [detailsLabel, setDetailsLabel] = useState(DETAILS);

    function toggleDetails() {
        setExpanded(!expanded);
        // Задержка перед изменением метки
        setTimeout(() => {
            setDetailsLabel(detailsLabel === DETAILS ? SHOW_LESS : DETAILS);
        }, LABEL_DELAY);
    }

    if (isEmptyOrNull(data)) {
        return null;
    }
    return (
        <div className="breed-info-block__wrapper">
            <div className="breed-info-block__header">
                <span>{data.sectionTitle}</span>
            </div>
            <div className="breed-info-block__aggregated-mark">
                <div className="progress-bar wide">
                    <div className={`progress ${getProgressBarColor(data.difficulty)}`}>
                        <span>{`${t('difficulty.' + data.difficulty)}`}</span>
                    </div>
                </div>
                <div className="aggregated-mark__mark-comment">
                    <span>{data.shortInfo}</span>
                </div>
            </div>
            <div className={`breed-info-block__details ${expanded ? 'expanded' : EMPTY}`}>
                <div className="details-label">
                    <span>Подробно</span>
                </div>
                <div className="breed-info-block__params">
                    {data.properties.map((param, index) => {
                        return <div key={index} className="breed-info-block__params-param">
                            <div className="param-label">
                                <span>{param.name}</span>
                            </div>
                            <div className="progress-bar thin">
                                <div className={`progress ${getProgressBarColor(param.data.difficulty)}`}>
                                    <span>{param.data.name}</span>
                                </div>
                            </div>
                        </div>
                    })}
                </div>
                <div className="details-summary">
                    <span>{data.description}</span>
                </div>
            </div>
            <div className="read-more-container" onClick={() => toggleDetails()}>
                <p className="read-more-text">{detailsLabel}</p>
            </div>
        </div>
    );
};

export default BreedInfoBlock;