export const DIFFICULTY_EASY = 'easy';
export const DIFFICULTY_MEDIUM = 'medium';
export const DIFFICULTY_HARD = 'hard';
export const DIFFICULTY_VERY_HARD = 'very_hard';

export const COLOR_GREEN = 'green';
export const COLOR_YELLOW = 'yellow';
export const COLOR_ORANGE = 'orange';
export const COLOR_RED = 'red';

/**
 * Получить цвет прогресс-бара по уровню сложности
 */
export function getProgressBarColor(difficulty) {
    switch (difficulty) {
        case DIFFICULTY_EASY:
            return COLOR_GREEN;
        case DIFFICULTY_MEDIUM:
            return COLOR_YELLOW;
        case DIFFICULTY_HARD:
            return COLOR_ORANGE;
        case DIFFICULTY_VERY_HARD:
            return COLOR_RED;
        default:
            return null;
    }
}