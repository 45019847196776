import React from 'react';
import {MIXED_BREED_TITLE, NO_BREED_TITLE} from "../../../../../app/const/appConst";
import {useDispatch} from "react-redux";
import {hideModal} from "../../../../../app/store/modalReducer";
import {storeItem} from "../../../../../components/common/form/helper/formHelper";
import {PET_BREED} from "../../../newpet/helpers/petFormConstants";

/**
 * Текст в модальном окне - помощь при выборе породы питомца при заведении/смене породы
 */
const ChoosingBreedTip = ({
                              mixedBreedId, noBreedId,
                              setInitialBreedId
                          }) => {
    const dispatch = useDispatch();
    /**
     * Просетить начальное значение породы питомца
     * */
    function doAction(id) {
        setInitialBreedId(id);
        storeItem(PET_BREED, id)
        dispatch(hideModal())
    }

    function getDefaultBreed(id, title) {
        if (id === 0) {
            return <span className="breed-input__tip-variant">{title}</span>
        }
        return <span className="breed-input__tip-variant selectable" onClick={() => doAction(id)}>{title}</span>
    }

    return (
        <div className="breed-input__tip-wrapper">
            <p className="breed-input__tip">
            Если ваш питомец является смесью двух или более пород, выберите опцию {getDefaultBreed(mixedBreedId, MIXED_BREED_TITLE)}.
            Если вы уверены, что ваш питомец принадлежит к определенной породе, но не видите её в списке,
            выберите {getDefaultBreed(noBreedId, NO_BREED_TITLE)} и сообщите об этом команде Milkins.
            Вы всегда сможете изменить породу питомца в его профиле.
            </p>
        </div>
    );
};

export default ChoosingBreedTip;