/**
 * Общие константы форм
 */

export const STRING = 'string';
export const NUMBER = 'number';
export const BOOLEAN = 'boolean';
//для паролей
export const HIDDEN = 'hidden';
//селект с единственным значением
export const SINGLE_OPTION = 'single_option'

// количество файлов, которых за раз может добавить пользователь
export const FILES_MAX_NUMBER = 10;

//кастомный инпут с марджином справа и уменьшенным шрифтом
export const CUSTOM_INPUT_CLASS = 'form__input right-margin-20 small-fontsize';
export const INPUT_RANGE_CLASS = 'form__input range';
export const ACTION_ADD = 'ADD';
export const ACTION_UPDATE = 'UPDATE';