import React from 'react';
import {EMPTY} from "../../../../app/const/appConst";

/**
 * Компонент для удобного выбора одного из двух вариантов
 * first: {value, title}
 * second: {value, title}
 *
 * Данный компонент используется ВНЕ формы
 */
const CustomSelectorTwoVarsInTable = ({
                                          value,
                                          setValue,
                                          first,
                                          second,
                                          disabled = false
                                      }) => {
    /**
     * Получаем className для опции переключателя
     */
    function getClassName(optionValue) {
        const baseClassName = optionValue === value ? "type-selector__item active" : "type-selector__item";
        const disabledOption = disabled ? "disabled" : EMPTY;
        return `${baseClassName} ${disabledOption}`
    }

    /**
     * Обновление значения происходит только если компонент не заблокирован
     */
    function updateValue(newValue) {
        if (!disabled) {
            setValue(newValue)
        }
    }

    return (
        <div className="type-selector">
            <div className="selector-options">
                <div className={getClassName(first.value)}
                     onClick={() => updateValue(first.value)}>
                    <span>{first.title}</span>
                </div>
                <div className={getClassName(second.value)}
                     onClick={() => updateValue(second.value)}>
                    <span>{second.title}</span>
                </div>
            </div>
        </div>
    );
};

export default CustomSelectorTwoVarsInTable;