import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {ANIMAL_KIND_STORED_ITEM, availableAnimalKinds, CAT, DOG} from "../../../app/const/appConst";
import CustomSelectorTwoVars from "../../../components/common/ui/select/CustomSelectorTwoVars";
import {storeItem} from "../../../components/common/form/helper/formHelper";
import {isEmptyOrNull} from "../../../app/helper/commonHelper";

/**
 * Фильтр по типу животных: собака/кошка
 */
const AnimalKindSelector = ({animalKind, setAnimalKind, catTitle = null, dogTitle = null, disabled = false}) => {
    const {t} = useTranslation();
    const CAT = {value: "CAT", title: t('page.breeds.cats')}
    const DOG = {value: "DOG", title: t('page.breeds.dogs')}


    function fillAnimalKindObj(animalKind) {
        switch (animalKind) {
            case CAT :
                setAnimalKind(prevState => ([...prevState, {
                    type: CAT.value,
                    title: catTitle ? catTitle : CAT.title
                }]))
                return;
            case DOG :
                setAnimalKind(prevState => ([...prevState, {
                    type: DOG.value,
                    title: dogTitle ? dogTitle : DOG.title
                }]))
                return;
            default:
                return;
        }
    }

    useEffect(() => {
        availableAnimalKinds.map(animalKind => fillAnimalKindObj(animalKind))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**
     * Сохраняем в стор значение animalKind при каждой смене
     */
    useEffect(() => {
        if (!isEmptyOrNull(animalKind)) {
            storeItem(ANIMAL_KIND_STORED_ITEM, animalKind)
        }
    }, [animalKind])


    return (
        <CustomSelectorTwoVars value={animalKind} setValue={setAnimalKind} first={CAT} second={DOG}
                               disabled={disabled}/>
    );
};

export default AnimalKindSelector;