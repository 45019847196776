import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {NUMBER, STRING} from "../../../../../../components/common/form/helper/formConstants";
import {navigateToTheNextStep, petFormStepOneSchema, removeCurrentStepFromValidated} from "../../../helpers/petHelper";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import FormError from "../../../../../../components/common/form/parts/inputs/wrappers/FormError";
import {getStoredItem, removeItem, storeItem} from "../../../../../../components/common/form/helper/formHelper";
import {
    NEW_PET_STEP_TWO_PATH,
    PET_ANIMAL_KIND,
    PET_BREED,
    PET_GENDER,
    PET_HOME_NAME
} from "../../../helpers/petFormConstants";
import {DEFAULT, EMPTY} from "../../../../../../app/const/appConst";
import BreedService from "../../../../../breeds/api/BreedService";
import {useNavigate} from "react-router";
import PetForm from "../PetForm";
import TextInputFormItem from "../../../../../../components/common/form/parts/inputs/wrappers/TextInputFormItem";
import PetAnimalKindFormItem from "../../../../common/components/form/PetAnimalKindFormItem";
import PetGenderFormItem from "../../../../common/components/form/PetGenderFormItem";
import OrangeButtonByCenter from "../../../../../../components/common/ui/buttons/OrangeButtonByCenter";
import PetBreedFormItem from "../../../../common/components/form/PetBreedFormItem";

/**
 * Форма добавления нового питомца
 */
const PetFormStepOne = () => {
    const CURRENT_STEP = 1;
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const [animalKind, setAnimalKind] = useState(DEFAULT); //нужно, чтобы отрисовывалось значение по умолчанию
    const [breeds, setBreeds] = useState([]);
    const [gender, setGender] = useState(getStoredItem(PET_GENDER, STRING));
    const [disableBreeds, setDisableBreeds] = useState(true); // блокирование поля с породами
    const [breedInfoErrorMessage, setBreedInfoErrorMessage] = useState(EMPTY);
    const {
        register,
        formState: {errors},
        handleSubmit,
        setValue
    } = useForm({
        resolver: yupResolver(petFormStepOneSchema(t)),
        mode: "onBlur",
        reValidateMode: "onBlur"
    })

    function submitForm() {
        navigateToTheNextStep(CURRENT_STEP, navigate, NEW_PET_STEP_TWO_PATH);
    }

    /**
     * Подставляем данные из стора, если они были
     */
    function init() {
        setValue(PET_HOME_NAME, getStoredItem(PET_HOME_NAME, STRING));
        setValue(PET_GENDER, getStoredItem(PET_GENDER, STRING));
        const storedAnimalKind = getStoredItem(PET_ANIMAL_KIND, STRING);
        if (storedAnimalKind.length !== 0) {
            setAnimalKind(storedAnimalKind);
            getBreeds(storedAnimalKind);
        }
    }

    function hasPetHomeNameValue() {
        return getStoredItem(PET_HOME_NAME, STRING) !== EMPTY
    }

    /**
     * Получаем породы питомцев
     */
    function getBreeds(animalKind) {
        setBreedInfoErrorMessage(EMPTY)
        const response = BreedService.getAll(animalKind, i18n.language);
        response.then((resp) => {
            const transformedBreeds = resp.data.data.map(breedInfo => ({
                id: breedInfo.breedId,
                translation: {
                    title: breedInfo.title
                }
            }));
            setBreeds(transformedBreeds);
            setBreedInfoErrorMessage(EMPTY)
            //разрешаем заполнять породу после того, как выбрали тип животного
            setDisableBreeds(false);
        }, (error) => {
            setBreedInfoErrorMessage(error.response?.data?.messages ?
                error.response?.data?.messages?.ERROR[0] : error.message)
            setDisableBreeds(true);
        });
    }

    /**
     * Получаем начальное значение для инпута с породами.
     * Если пользователь уже вводил значение - оно отложится в сторе. Иначе вернется значение по умолчанию - ноль
     */
    function getBreedInitialId() {
        return getStoredItem(PET_BREED, NUMBER);
    }

    function onChangeAnimalKind(selectedSort) {
        setAnimalKind(selectedSort);
        // при смене типа животного удаляем сохраненное значение породы из стора
        removeItem(PET_BREED);
        getBreeds(selectedSort);
    }

    useEffect(() => {
        window.scrollTo({top: 0, left: 0});
        removeCurrentStepFromValidated(CURRENT_STEP);
        init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <PetForm handleSubmit={handleSubmit} submitForm={submitForm}>
            <TextInputFormItem errors={errors} fieldName={PET_HOME_NAME} placeholder={t('page.pet.newPet.name')}
                               register={register} hasValue={hasPetHomeNameValue()} tabIndex='1'
                               onBlurFunction={storeItem}/>
            <PetAnimalKindFormItem animalKind={animalKind} onChangeFunction={onChangeAnimalKind}
                                   onBlurFunction={storeItem}/>
            <PetBreedFormItem breeds={breeds} register={register} setValue={setValue} errors={errors}
                              tabIndex='5' initialId={getBreedInitialId()} disabled={disableBreeds}/>

            <FormError title={breedInfoErrorMessage}/>
            <PetGenderFormItem value={gender} setValue={setGender} register={register}
                               hookFormSetValue={setValue} toStore={true} errors={errors}/>

            <OrangeButtonByCenter isActive={true} onClick={handleSubmit(submitForm)}>
                Вперёд
            </OrangeButtonByCenter>

        </PetForm>
    );
};

export default PetFormStepOne;