/**
 * Константы для форм из пакета GEO
 */
import {NUMBER, STRING} from "../../../../components/common/form/helper/formConstants";

/**
 * Метадата по континентам
 */
export const CONTINENTS_METADATA = {
    backControllerName: 'continents',
    titlePrepositionalCase: 'adminPanel.dictionaries.continents.titlePrepositionalCase',
    fields: []
}

//Поля формы со страной:
export const COUNTRY_ID = "countryId";
export const COUNTRY_ALPHA2_CODE = "countryAlpha2Code";
export const COUNTRY_ALPHA3_CODE = "countryAlpha3Code";
export const COUNTRY_ISO_CODE = "countryIsoCode";
export const COUNTRY_TRANSLATION_ID = "countryTranslationId";
export const COUNTRY_TRANSLATION_TITLE = "countryTitle";
export const COUNTRY_CODE = "countryCode";
export const COUNTRY_CONTINENT_ID = "continentId";

//
export const COUNTRY_METADATA = {
    title: 'adminPanel.dictionaries.countries.title',
    backControllerName: 'countries',
    titlePrepositionalCase: 'adminPanel.dictionaries.countries.titlePrepositionalCase',
    fields: [
        {title: COUNTRY_ID, type: NUMBER},
        {title: COUNTRY_ALPHA2_CODE, type: STRING},
        {title: COUNTRY_ALPHA3_CODE, type: STRING},
        {title: COUNTRY_ISO_CODE, type: NUMBER},
        {title: COUNTRY_TRANSLATION_ID, type: NUMBER},
        {title: COUNTRY_TRANSLATION_TITLE, type: STRING},
        {title: COUNTRY_CONTINENT_ID, type: NUMBER}
    ]
}

