import React from 'react';
import RangeFormItem from "../../../../components/common/form/parts/inputs/wrappers/RangeFormItem";
import {
    FEMALE_LIFE_FROM,
    FEMALE_LIFE_RANGE_ID, FEMALE_LIFE_TO,
    FEMALE_SIZE_FROM,
    FEMALE_SIZE_RANGE_ID, FEMALE_SIZE_TO, FEMALE_WEIGHT_FROM, FEMALE_WEIGHT_RANGE_ID, FEMALE_WEIGHT_TO,
    MALE_LIFE_FROM,
    MALE_LIFE_RANGE_ID, MALE_LIFE_TO,
    MALE_SIZE_FROM,
    MALE_SIZE_RANGE_ID,
    MALE_SIZE_TO,
    MALE_WEIGHT_FROM,
    MALE_WEIGHT_RANGE_ID, MALE_WEIGHT_TO
} from "../../helpers/breedFormConstants";
import {hasValue} from "../../../../app/helper/commonHelper";
import {ACTION_ADD} from "../../../../components/common/form/helper/formConstants";
import {storeItem} from "../../../../components/common/form/helper/formHelper";
import {useTranslation} from "react-i18next";

/**
 * Часть формы по работе с Породой - "Гендерные параметры"
 */
const BreedGenderParams = ({action, allValues, register, errors}) => {
    const {t} = useTranslation();

    return (
        <div className="gender-form-params__wrapper">
            <div className="gender-form-params male">
                <div className="gender-form-params__header">
                    <span>Для самцов</span>
                </div>
                {/*следующие два диапазона относятся к сущности appearance*/}
                <RangeFormItem rangeFieldId={MALE_SIZE_RANGE_ID} title={t("form.newBreed.size")}
                               fieldNameFrom={MALE_SIZE_FROM} fieldNameTo={MALE_SIZE_TO}
                               hasValueFrom={hasValue(allValues[MALE_SIZE_FROM])} hasValueTo={hasValue(allValues[MALE_SIZE_TO])}
                               tabIndexFrom='5' tabIndexTo='6'
                               errors={errors} register={register}
                               onBlurFunction={action === ACTION_ADD ? storeItem : null}/>

                <RangeFormItem rangeFieldId={MALE_WEIGHT_RANGE_ID} title={t("form.newBreed.weight")}
                               fieldNameFrom={MALE_WEIGHT_FROM} fieldNameTo={MALE_WEIGHT_TO}
                               hasValueFrom={hasValue(allValues[MALE_WEIGHT_FROM])} hasValueTo={hasValue(allValues[MALE_WEIGHT_TO])}
                               tabIndexFrom='7' tabIndexTo='8' errors={errors} register={register}
                               onBlurFunction={action === ACTION_ADD ? storeItem : null}/>

                {/*следующий диапазон относятся к сущности health */}
                <RangeFormItem rangeFieldId={MALE_LIFE_RANGE_ID} title={t("form.newBreed.life")}
                               fieldNameFrom={MALE_LIFE_FROM} fieldNameTo={MALE_LIFE_TO}
                               hasValueFrom={hasValue(allValues[MALE_LIFE_FROM])} hasValueTo={hasValue(allValues[MALE_LIFE_TO])}
                               tabIndexFrom='9' tabIndexTo='10' errors={errors} register={register}
                               onBlurFunction={action === ACTION_ADD ? storeItem : null}/>
            </div>


            <div className="gender-form-params female">
                <div className="gender-form-params__header">
                    <span>Для самок</span>
                </div>
                {/*следующие два диапазона относятся к сущности appearance*/}
                <RangeFormItem rangeFieldId={FEMALE_SIZE_RANGE_ID} title={t("form.newBreed.size")}
                               fieldNameFrom={FEMALE_SIZE_FROM} fieldNameTo={FEMALE_SIZE_TO}
                               hasValueFrom={hasValue(allValues[FEMALE_SIZE_FROM])} hasValueTo={hasValue(allValues[FEMALE_SIZE_TO])}
                               tabIndexFrom='11' tabIndexTo='12'
                               errors={errors} register={register}
                               onBlurFunction={action === ACTION_ADD ? storeItem : null}/>

                <RangeFormItem rangeFieldId={FEMALE_WEIGHT_RANGE_ID} title={t("form.newBreed.weight")}
                               fieldNameFrom={FEMALE_WEIGHT_FROM} fieldNameTo={FEMALE_WEIGHT_TO}
                               hasValueFrom={hasValue(allValues[FEMALE_WEIGHT_FROM])} hasValueTo={hasValue(allValues[FEMALE_WEIGHT_TO])}
                               tabIndexFrom='13' tabIndexTo='14' errors={errors} register={register}
                               onBlurFunction={action === ACTION_ADD ? storeItem : null}/>

                {/*следующий диапазон относятся к сущности health */}
                <RangeFormItem rangeFieldId={FEMALE_LIFE_RANGE_ID} title={t("form.newBreed.life")}
                               fieldNameFrom={FEMALE_LIFE_FROM} fieldNameTo={FEMALE_LIFE_TO}
                               hasValueFrom={hasValue(allValues[FEMALE_LIFE_FROM])} hasValueTo={hasValue(allValues[FEMALE_LIFE_TO])}
                               tabIndexFrom='15' tabIndexTo='16' errors={errors} register={register}
                               onBlurFunction={action === ACTION_ADD ? storeItem : null}/>
            </div>
        </div>
    );
};

export default BreedGenderParams;