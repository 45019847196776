import React from 'react';
import {DEFAULT_PAGE_LIMIT} from "../../../../app/const/appConst";

/**
 * Пагинация
 */
const Pagination = ({
                        totalRecords,
                        pageLimit = DEFAULT_PAGE_LIMIT,
                        pageNeighbours = 0,
                        currentPage = 1,
                        setCurrentPage
                    }) => {
    const LEFT_PAGE = 'LEFT';
    const RIGHT_PAGE = 'RIGHT';
    const totalPages = Math.ceil(totalRecords / pageLimit);
    const pages = fetchPageNumbers();

    /**
     * range(1, 5) => [1, 2, 3, 4, 5]
     */
    function getRange(from, to, step = 1) {
        let i = from;
        const range = [];

        while (i <= to) {
            range.push(i);
            i += step;
        }
        return range;
    }

    /**
     * К примеру у нас 10 страниц и мы определили, что должно быть по 2 соседа
     * Допустим текущая страница - 6
     * Тогда панель пагинации должна выглядеть следующим образом:
     *
     * (1) < {4 5} [6] {7 8} > (10)
     *
     * (x) => граничные номера страниц: первая и последняя(всегда видимы)
     * [x] => текущая страница
     * {...x} => соседи текущей страницы
     */
    function fetchPageNumbers() {
        /**
         * totalNumbers: общее количество номеров страниц отображаемых на панели пагинации
         * totalBlocks: totalNumbers + 2 - общее количество блоков, включая (<) и (>)
         */
        const totalNumbers = (pageNeighbours * 2) + 3;
        const totalBlocks = totalNumbers + 2;

        if (totalPages > totalBlocks) {
            const startPage = Math.max(2, currentPage - pageNeighbours);
            const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours);
            let pages = getRange(startPage, endPage);

            /**
             * hasLeftSpill: имеет скрытые номера страниц слева
             * hasRightSpill: имеет скрытые номера страниц справа
             * spillOffset: количество скрытых страниц слева или справа
             */
            const hasLeftSpill = startPage > 2;
            const hasRightSpill = (totalPages - endPage) > 1;
            const spillOffset = totalNumbers - (pages.length + 1);

            switch (true) {
                // handle: (1) < {5 6} [7] {8 9} (10)
                case (hasLeftSpill && !hasRightSpill): {
                    const extraPages = getRange(startPage - spillOffset, startPage - 1);
                    pages = [LEFT_PAGE, ...extraPages, ...pages];
                    break;
                }

                // handle: (1) {2 3} [4] {5 6} > (10)
                case (!hasLeftSpill && hasRightSpill): {
                    const extraPages = getRange(endPage + 1, endPage + spillOffset);
                    pages = [...pages, ...extraPages, RIGHT_PAGE];
                    break;
                }

                // handle: (1) < {4 5} [6] {7 8} > (10)
                case (hasLeftSpill && hasRightSpill):
                default: {
                    pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
                    break;
                }
            }
            return [1, ...pages, totalPages];
        }
        return getRange(1, totalPages);
    }

    /**
     * Просечиваем новую страницу, только если пользователь нажал не на текущую активную
     */
    function handleClick (page) {
        if (currentPage !== page) {
            setCurrentPage(page);
        }
    }

    /**
     * Обработка нажатия на стрелку "влево"
     */
    function handleMoveLeft () {
        setCurrentPage(currentPage - (pageNeighbours * 2) - 1);
    }

    /**
     * Обработка нажатия на стрелку "вправо"
     */
    function handleMoveRight() {
        setCurrentPage(currentPage + (pageNeighbours * 2) + 1);
    }

    return (
        <div className="pagination-wrapper">
            <ul className="pagination">
                {pages.map((page, index) => {
                    if (page === LEFT_PAGE) return (
                        <li key={index} className="page-item">
                            <span className="page-link" onClick={() => handleMoveLeft()}>
                                <span aria-hidden="true">&laquo;</span>
                                <span className="sr-only">Previous</span>
                            </span>
                        </li>
                    );

                    if (page === RIGHT_PAGE) return (
                        <li key={index} className="page-item">
                            <span className="page-link" onClick={() => handleMoveRight()}>
                                <span aria-hidden="true">&raquo;</span>
                                <span className="sr-only">Next</span>
                            </span>
                        </li>
                    );

                    return (
                        <li key={index} className={`page-item${currentPage === page ? ' active' : ''}`}>
                            <span className="page-link" onClick={() => handleClick(page)}>{page}</span>
                        </li>
                    );
                })
                }
            </ul>
        </div>
    );
};

export default Pagination;