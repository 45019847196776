import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import Content from "../../components/common/Content";
import BreedService from "./api/BreedService";
import Breadcrumbs from "../../components/common/Breadcrumbs";
import PageTitle from "../../components/common/PageTitle";
import {useTranslation} from "react-i18next";
import Spinner from "../../components/common/Spinner";
import FormError from "../../components/common/form/parts/inputs/wrappers/FormError";
import BreedForm from "./components/form/BreedForm";
import {ACTION_UPDATE} from "../../components/common/form/helper/formConstants";
import {isEmptyOrNull} from "../../app/helper/commonHelper";

/**
 * Компонент по обновлению информации о породе животного, а также работе с переводами
 */
const BreedTranslations = () => {
    const params = useParams();
    const {t} = useTranslation()
    const [breed, setBreed] = useState({});
    const [img, setImg] = useState({});
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");


    function getBreedInfo() {
        const response = BreedService.getBreedDataById(Number(params.id));
        response.then((resp) => {
            const data = resp.data.data;
            setBreed(data);
        }, (error) => {
            setError(true)
            setErrorMessage(error.response?.data?.messages ?
                error.response?.data?.messages?.ERROR[0] : error.message)
        })
    }

    function getBreedImage() {
        const response = BreedService.getImageByBreedCode(breed.code);
        response.then((resp) => {
            if (resp.data.size === 0) {
                setImg({data_url: "", file: {}})
                return;
            }
            let header = resp.headers['content-disposition'];
            const myBlob = resp.data;
            myBlob.name = header.substring(header.indexOf('=') + 1)
            myBlob.lastModified = new Date();
            const fileImage = new File([myBlob], myBlob.name, {
                type: myBlob.type
            });

            getBase64FromBlob(myBlob).then((resp) =>
                setImg({data_url: resp, file: URL.createObjectURL(fileImage)})
            );
        }, (error) => {
            setError(true)
            setErrorMessage(error.response?.data?.messages ?
                error.response?.data?.messages?.ERROR[0] : error.message);
        })
    }

    function getBase64FromBlob(blob) {
        return new Promise((resolve, _) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob);
        });
    }

    useEffect(() => {
        getBreedInfo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isEmptyOrNull(breed)) {
            return;
        }
        getBreedImage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [breed]);
    return (
        <Content>
            {error ? <FormError title={errorMessage}/> : null}
            {Object.keys(breed).length === 0 || Object.keys(img).length === 0 ? <Spinner/> :
                <>
                    <Breadcrumbs elements={[
                        {page: t("page.actions.edit"), path: '/breeds'},
                        {page: breed.translation?.title, path: `/breeds/${params.id}`},
                        {page: t("page.breed.edit"), path: ''}
                    ]}/>
                    <PageTitle title={t("page.breed.edit")}/>
                    <BreedForm action={ACTION_UPDATE} breed={breed} image={Object.keys(img).length === 0 ? null : img}/>
                </>
            }
        </Content>
    );
};

export default BreedTranslations;