import React, {useEffect, useState} from 'react';
import TableNoResults from "../../../components/common/table/TableNoResults";
import {useTranslation} from "react-i18next";
import Spinner from "../../../components/common/Spinner";
import Breadcrumbs from "../../../components/common/Breadcrumbs";
import ArticleWithHeader from "../../../components/common/ui/text/ArticleWithHeader";
import SourceLinks from "../../../components/common/ui/text/SourceLinks";
import {useParams} from "react-router-dom";
import BreedTitle from "./BreedTitle";
import {hasValue} from "../../../app/helper/commonHelper";
import GenderCharacteristics from "../GenderCharacteristics";
import {formGenderParams} from "../helpers/breedHelper";
import BreedInfoBlock from "./BreedInfoBlock";

/**
 * Компонент, отвечающий за отображение информации о породе животного
 */
const BreedInfo = ({breedInfo, image, errorMessage, isLoading, isImgLoading}) => {
    const params = useParams();
    const {t} = useTranslation()
    const hasImage = Object.keys(image).length !== 0;
    //гендерно-зависимые параметры
    const [allGendersParams, setAllGendersParams] = useState({male: [], female: []});

    useEffect(() => {
        formGenderParams(breedInfo, setAllGendersParams);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [breedInfo])

    if (isLoading || errorMessage !== '') {
        return <TableNoResults title={t("page.breed.infoNotFound")}/>;
    }

    return (
        <div>
            {isImgLoading ?
                <Spinner/> :
                hasImage ?
                    <div className="breed-photo__wrap-around">
                        <div className="breed-photo">
                            <img src={image} alt=""/>
                        </div>
                    </div>
                    : null
            }

            <Breadcrumbs
                elements={[{page: t("page.breeds.title"), path: '/breeds'},
                    {page: breedInfo.translation.title, path: ''}]}/>
            <BreedTitle title={breedInfo.translation.title} breedId={params.id} hasImage={hasImage}/>

            <GenderCharacteristics genderParams={allGendersParams}/>

            {hasValue(breedInfo.translation.breedFacts) ?
                <div className="breed-facts">
                    {breedInfo.translation.breedFacts?.map((item, index) =>
                        <div key={index} className="breed-facts__fact"><p>{item.fact}</p></div>
                    )}
                </div>
                : null
            }
            <BreedInfoBlock data={breedInfo.care}/>

            {hasValue(breedInfo?.country) ?
                <ArticleWithHeader title={t("page.breed.section.origin")}
                                   text={breedInfo.country}/> : null}

            {hasValue(breedInfo?.translation.breedHistory) ?
                <ArticleWithHeader title={t("page.breed.section.breedHistory")}
                                   text={breedInfo?.translation.breedHistory}/> : null}
            {hasValue(breedInfo?.translation.behaviour) ?
                <ArticleWithHeader title={t("page.breed.section.behaviour")}
                                   text={breedInfo?.translation.behaviour}/> : null}
            {hasValue(breedInfo?.translation.sources) ?
                <SourceLinks sources={breedInfo?.translation.sources}/> : null}
        </div>
    );
};

export default BreedInfo;