import React, {useEffect, useRef, useState} from 'react';
import {hasValue, isEmptyOrNull} from "../../../../../../app/helper/commonHelper";
import {LABEL_CLASSNAME_WITHOUT_VALUE} from "../../../helper/commonFormConstants";
import {movePlaceholderDown, movePlaceholderUp, removeItem, storeItem} from "../../../helper/formHelper";
import {useFilteredItemsForInputWithOptions} from "../../../hooks/useFilteredItemsForInputWithOptions";
import FormItem from "./FormItem";
import FormError from "./FormError";
import {EMPTY} from "../../../../../../app/const/appConst";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons/faTrash";

/**
 * Компонент текстового инпута с выпадающим списком.
 * Может либо работать с управляемым элементом, либо записывать значение в стор.
 *
 * setValue - функция из хука useForm
 */
const TextInputWithOptionsFormItem = ({
                                          fieldName, register, options, setValue, placeholder, tabIndex,
                                          initialId = 0, disabled = false, errors = null,
                                          isNeedToStore = true
                                      }) => {
    const inputRef = useRef(EMPTY);
    const [labelClass, setLabelClass] = useState(LABEL_CLASSNAME_WITHOUT_VALUE);
    const [emptyResult, setEmptyResult] = useState(false)
    // значение, которое отображается в инпуте
    const [inputValue, setInputValue] = useState(EMPTY);
    //компонент отображения списка выбора
    const [showDropdown, setShowDropdown] = useState(false);
    const dropdownRef = useRef(null);

    // фильтруем элементы
    const filteredItems = useFilteredItemsForInputWithOptions(options, inputRef.current.value, setEmptyResult)

    /**
     * Просечиваем в инпут значение по умолчанию при смене опций выбора или же начального значения,
     * которое может изменить в процессе
     */
    useEffect(() => {
        setInitialValueIntoInput();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options, initialId]);

    /**
     * Просетить начальное значение в инпут
     */
    function setInitialValueIntoInput() {
        const initId = Number(initialId)
        if (initId === null || initId === 0 || isEmptyOrNull(options) || options.length === 0) {
            //просечиваем пустое значение
            setInputValue(EMPTY);
            setValue(fieldName, EMPTY)
            return;
        }
        const option = options.find(el => Number(el.id) === initId);
        if (hasValue(option)) {
            setInputValue(option.translation.title);
            setValue(fieldName, initId)
            inputRef.current.value = option.translation.title;
        }
        movePlaceholder(showDropdown);
    }

    /**
     * Обработка события изменения в инпуте
     */
    function handleInputChange(val) {
        setInputValue(val);
        if (!showDropdown) {
            setShowDropdown(true);
        }
        movePlaceholder(showDropdown);
    }

    /**
     * Обработка события получения фокуса в инпуте
     */
    function handleInputFocus() {
        if (isEmptyOrNull(inputValue)) {
            setShowDropdown(true)
        }
        movePlaceholder(showDropdown);
    }

    const handleWheel = (event) => {
        const node = dropdownRef.current;
        if (node) {
            const {scrollTop, scrollHeight, clientHeight} = node;
            const atTop = scrollTop === 0;
            const atBottom = scrollTop + clientHeight === scrollHeight;

            if ((atTop && event.deltaY < 0) || (atBottom && event.deltaY > 0)) {
                event.preventDefault();
            }
        }
    };

    /**
     * Обновить значение в инпуте
     * optionId === -1 -- признак отсутствия опций для выбора
     */
    function updateSelectedOption(optionId, optionTitle) {
        //если поле не заполнено, то зануляем в setValue
        if (optionId === null || optionId === -1 || isEmptyOrNull(optionTitle)) {
            setValue(fieldName, EMPTY)
            return
        }
        if (isNeedToStore) {
            storeItem(fieldName, optionId)
        }
        //просечиваем в управляемый компонент хука формы
        setValue(fieldName, optionId)
        //просечиваем текст в инпут
        setInputValue(optionTitle);
        setShowDropdown(false);
        movePlaceholder(showDropdown);
    }

    /**
     * Обработка события нажатия на треугольник
     */
    function handleArrowClick() {
        setShowDropdown(!showDropdown);
        movePlaceholder(!showDropdown);
    }

    /**
     * Обработка потери фокуса инпутом.
     */
    function handleOnBlur() {
        movePlaceholder(showDropdown);
    }

    /**
     * Смещать ли плейсхолдер в инпут при потере фокуса полем
     */
    function movePlaceholder(isDropdownOptionsShow) {
        if (!isDropdownOptionsShow && isEmptyOrNull(inputRef.current.value)) {
            movePlaceholderDown(setLabelClass)
        } else {
            movePlaceholderUp(setLabelClass);
        }
    }

    /**
     * Если значение в инпуте не пустое - отображаем корзину
     */
     function getTrash() {
        if (inputValue !== EMPTY) {
            return <div className="input-with-options__trash-wrapper" onClick={() => clearInputValue()}>
                <FontAwesomeIcon icon={faTrash} size={"xl"}/>
            </div>
        }
        return null;
     }

    /**
     * Очистка значения в инпуте
     */
    function clearInputValue() {
        if (isNeedToStore) {
            removeItem(fieldName);
        }
        setInputValue(EMPTY);
        setValue(fieldName, EMPTY);
        movePlaceholderDown(setLabelClass)
    }

    return (
        <FormItem>
            <div className='input-with-options-wrapper'>
                {/*Корзина для очистки значения в форме*/}
                {getTrash()}
                <label htmlFor={fieldName} className={labelClass}>{placeholder}</label>
                {/*Инпут*/}
                <input
                    id={fieldName}
                    type="text"
                    className="form__input"
                    {...register(fieldName)}
                    ref={inputRef}
                    value={inputValue}
                    onChange={(e) => handleInputChange(e.target.value)}
                    onFocus={handleInputFocus}
                    onBlur={handleOnBlur}
                    tabIndex={tabIndex}
                    disabled={disabled}
                    autoComplete="off"
                />
                {/*Стрелка*/}
                <div className="input-field-arrow__wrapper" onClick={handleArrowClick}>
                    <div className={`input-field-arrow ${showDropdown ? '' : 'collapsed'}`}></div>
                </div>
                {/*выпадающие элементы*/}
                <div className={`dropdown-menu ${showDropdown ? 'show' : ''}`}>
                    <div className="dropdown-menu__content" onWheel={handleWheel} ref={dropdownRef}>
                        {filteredItems.map((option) => (
                            <div key={option.id} className={`dropdown-item ${emptyResult ? 'no-items' : ''}`}
                                 onClick={() => updateSelectedOption(option.id, option.translation.title)}>
                                <span>{option?.translation?.title}</span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {errors === null ? null : errors[fieldName] ?
                <FormError title={errors[fieldName].message} additionalStyle="margin-30"/> : null}
        </FormItem>
    );
};

export default TextInputWithOptionsFormItem;