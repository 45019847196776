import React, {useEffect, useState} from 'react';
import TableNoResults from "../../../../components/common/table/TableNoResults";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import DesktopBreedsTable from "./desktop/DesktopBreedsTable";
import MobileBreedsTable from "./mobile/MobileBreedsTable";

/**
 * Компонент таблицы с информацией о породах животных
 * содержит шапку с обозначением полей и строки со значениями
 **/
const BreedsTable = (props) => {
    const {t} = useTranslation();
    const isMobile = useSelector(state => state.mobileReducer.isMobile);
    const isModerator = useSelector(state => state.userReducer.isModerator);
    const isAdmin = useSelector(state => state.userReducer.isAdmin);
    const [breeds, setBreeds] = useState([]);

    useEffect(() => {
        // Фильтрация списка по параметру visible и обновление состояния
        if (isAdmin || isModerator) {
            setBreeds(props.breedsInfo);
        } else {
            setBreeds(props.breedsInfo.filter(breed => !breed.technical));
        }
    }, [isAdmin, isModerator, props.breedsInfo]);

    if (props.error !== "") {
        return (<TableNoResults title={props.error}/>);
    }
    if (!breeds.length) {
        return (<TableNoResults title={t("page.breeds.noBreedFound")}/>);
    }

    if (isMobile) {
        return <MobileBreedsTable breedsInfo={breeds}/>
    }
    return <DesktopBreedsTable breedsInfo={breeds}/>

};

export default BreedsTable;