import {BOOLEAN, NUMBER, STRING} from "../../../components/common/form/helper/formConstants";

/**
 * Поля формы породы
 */
export const BREED_ID = "breedId";
export const BREED_CODE = "breedCode";
export const BREED_TRANSLATION_ID = "translationId";
export const BREED_TITLE = "title";
export const BREED_APPEARANCE_ID = "appearanceId";
export const HEALTH_ID = "healthId";
export const BREED_FACTS = "breedFacts";
export const SOURCES = "sources";
export const BREED_HISTORY = "breedHistory";
//MALE
//size
export const MALE_SIZE_RANGE_ID = "maleSizeRangeId";
export const MALE_SIZE_FROM = "maleSizeFrom";
export const MALE_SIZE_TO = "maleSizeTo";
//weight
export const MALE_WEIGHT_RANGE_ID = "maleWeightRangeId";
export const MALE_WEIGHT_FROM = "maleWeightFrom";
export const MALE_WEIGHT_TO = "maleWeightTo";
//age
export const MALE_LIFE_RANGE_ID = "maleLifeRangeId";
export const MALE_LIFE_FROM = "maleLifeFrom";
export const MALE_LIFE_TO = "maleLifeTo";
//FEMALE
//size
export const FEMALE_SIZE_RANGE_ID = "femaleSizeRangeId";
export const FEMALE_SIZE_FROM = "femaleSizeFrom";
export const FEMALE_SIZE_TO = "femaleSizeTo";
//weight
export const FEMALE_WEIGHT_RANGE_ID = "femaleWeightRangeId";
export const FEMALE_WEIGHT_FROM = "femaleWeightFrom";
export const FEMALE_WEIGHT_TO = "femaleWeightTo";
//age
export const FEMALE_LIFE_RANGE_ID = "femaleLifeRangeId";
export const FEMALE_LIFE_FROM = "femaleLifeFrom";
export const FEMALE_LIFE_TO = "femaleLifeTo";
export const BREED_CARE_ID = "breedCareId";
export const CARE = "care";
export const CARE_LEVEL = "careLevel";
export const CARE_GROOMING = "careGrooming";
export const CARE_BATHING = "careBathing";
export const CARE_EAR_CLEANING = "careEarCleaning";
export const CARE_EYE_CLEANING = "careEyeCleaning";
export const CARE_TEETH_CLEANING = "careTeethCleaning";

export const BEHAVIOUR = "behaviour";

export const BORROWED_OBJECT = 'borrowedObject'
export const FACT = 'fact'
export const SOURCE_LINK = 'sourceLink'
export const LINK_DESCRIPTION = 'linkDescription'
export const BREED_COUNTRY_ID = 'breedCountryId'
export const BREED_IS_TECHNICAL = 'breedIsTechnical'

export const BREED_METADATA = {
    title: 'page.breed.title',
    backControllerName: 'breeds',
    titlePrepositionalCase: 'page.breed.titlePrepositionalCase',
    fields: [
        {title: BREED_ID, type: NUMBER},
        {title: HEALTH_ID, type: NUMBER},
        {title: BREED_CODE, type: STRING},
        {title: BREED_COUNTRY_ID, type: NUMBER},
        {title: BREED_TRANSLATION_ID, type: NUMBER},
        {title: BREED_APPEARANCE_ID, type: NUMBER},
        {title: BREED_TITLE, type: STRING},
        {title: BREED_HISTORY, type: STRING},
        {title: MALE_SIZE_RANGE_ID, type: NUMBER},
        {title: MALE_SIZE_FROM, type: NUMBER},
        {title: MALE_SIZE_TO, type: NUMBER},
        {title: MALE_WEIGHT_RANGE_ID, type: NUMBER},
        {title: MALE_WEIGHT_FROM, type: NUMBER},
        {title: MALE_WEIGHT_TO, type: NUMBER},
        {title: MALE_LIFE_RANGE_ID, type: NUMBER},
        {title: MALE_LIFE_FROM, type: NUMBER},
        {title: MALE_LIFE_TO, type: NUMBER},
        {title: FEMALE_SIZE_RANGE_ID, type: NUMBER},
        {title: FEMALE_SIZE_FROM, type: NUMBER},
        {title: FEMALE_SIZE_TO, type: NUMBER},
        {title: FEMALE_WEIGHT_RANGE_ID, type: NUMBER},
        {title: FEMALE_WEIGHT_FROM, type: NUMBER},
        {title: FEMALE_WEIGHT_TO, type: NUMBER},
        {title: FEMALE_LIFE_RANGE_ID, type: NUMBER},
        {title: FEMALE_LIFE_FROM, type: NUMBER},
        {title: FEMALE_LIFE_TO, type: NUMBER},
        {title: BREED_CARE_ID, type: NUMBER},
        {title: CARE, type: STRING},
        {title: CARE_LEVEL, type: STRING},
        {title: CARE_GROOMING, type: STRING},
        {title: CARE_BATHING, type: STRING},
        {title: CARE_EAR_CLEANING, type: STRING},
        {title: CARE_EYE_CLEANING, type: STRING},
        {title: CARE_TEETH_CLEANING, type: STRING},
        {title: BEHAVIOUR, type: STRING},
        {title: BREED_FACTS, type: STRING},
        {title: SOURCES, type: STRING},
        {title: BREED_IS_TECHNICAL, type: BOOLEAN}
    ]
}