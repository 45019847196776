import React, {useEffect, useRef, useState} from 'react';
import AnimalKindSelector from "../AnimalKindSelector";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import FormItem from "../../../../components/common/form/parts/inputs/wrappers/FormItem";
import {
    BEHAVIOUR,
    BREED_APPEARANCE_ID, BREED_CARE_ID,
    BREED_CODE,
    BREED_COUNTRY_ID,
    BREED_FACTS,
    BREED_HISTORY,
    BREED_ID,
    BREED_IS_TECHNICAL,
    BREED_METADATA,
    BREED_TITLE,
    BREED_TRANSLATION_ID,
    CARE,
    CARE_BATHING,
    CARE_EAR_CLEANING,
    CARE_EYE_CLEANING,
    CARE_GROOMING,
    CARE_LEVEL,
    CARE_TEETH_CLEANING,
    FEMALE_LIFE_FROM,
    FEMALE_LIFE_RANGE_ID,
    FEMALE_LIFE_TO,
    FEMALE_SIZE_FROM,
    FEMALE_SIZE_RANGE_ID,
    FEMALE_SIZE_TO,
    FEMALE_WEIGHT_FROM,
    FEMALE_WEIGHT_RANGE_ID,
    FEMALE_WEIGHT_TO,
    HEALTH_ID,
    MALE_LIFE_FROM,
    MALE_LIFE_RANGE_ID,
    MALE_LIFE_TO,
    MALE_SIZE_FROM,
    MALE_SIZE_RANGE_ID,
    MALE_SIZE_TO,
    MALE_WEIGHT_FROM,
    MALE_WEIGHT_RANGE_ID,
    MALE_WEIGHT_TO,
    SOURCES
} from "../../helpers/breedFormConstants";
import {
    formBreedSchema,
    formDto,
    getEntityNextId,
    getStoredFacts,
    getStoredSources,
    initFacts,
    initSourceLinks
} from "../../helpers/breedHelper";
import {
    clearSessionDataForEntity,
    getStoredItem,
    isFieldFilled,
    storeItem
} from "../../../../components/common/form/helper/formHelper";
import FormImage from "../../../../components/common/form/parts/inputs/wrappers/FormImage";
import HiddenInput from "../../../../components/common/form/parts/inputs/HiddenInput";
import {
    ACTION_ADD,
    ACTION_UPDATE,
    BOOLEAN,
    FILES_MAX_NUMBER,
    NUMBER,
    STRING
} from "../../../../components/common/form/helper/formConstants";
import BreedService from "../../api/BreedService";
import {useNavigate} from "react-router-dom";
import {getLanguageShortName} from "../../../../utils/langUtils";
import {CAT, EMPTY} from "../../../../app/const/appConst";
import {useSelector} from "react-redux";
import Form from "../../../../components/common/form/abstract/Form";
import SubmitSection from "../../../../components/common/form/parts/controlsection/SubmitSection";
import TextAreaFormItem from "../../../../components/common/form/parts/inputs/wrappers/TextAreaFormItem";
import TextInputFormItem from "../../../../components/common/form/parts/inputs/wrappers/TextInputFormItem";
import {hasValue} from "../../../../app/helper/commonHelper";
import BreedFactsFormElement from "./BreedFactsFormElement";
import BreedSourceLinksFormElement from "./BreedSourceLinksFormElement";
import {getDictionaryEnums, getDictionaryRecordsAsOptions} from "../../../../service/dictionaryService";
import {COUNTRY_METADATA} from "../../../adminpanel/dictionaries/geo/geoFormsConstants";
import breedInfo from "../BreedInfo";
import TextInputWithOptionsFormItem
    from "../../../../components/common/form/parts/inputs/wrappers/TextInputWithOptionsFormItem";
import CheckboxFormItem from "../../../../components/common/form/parts/inputs/wrappers/CheckboxFormItem";
import BreedGenderParams from "./BreedGenderParams";
import RadioSet from "../../../../components/common/form/parts/inputs/RadioSet";

/**
 * Форма добавления породы животного
 * action = ADD или UPDATE - чтобы разделить логику форм
 */
const BreedForm = ({action, breed = {}, image = {}}) => {
    const navigate = useNavigate();
    const {t, i18n} = useTranslation();
    const token = useSelector(state => state.userReducer.token);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(EMPTY);
    const [loading, setIsLoading] = useState(false);

    const {
        register,
        formState: {errors},
        handleSubmit,
        watch,
        setValue,
        getValues
    } = useForm({
        resolver: yupResolver(formBreedSchema(t)),
        mode: "onBlur",
        reValidateMode: "onBlur"
    })
    const [animalKind, setAnimalKind] = useState(action === ACTION_ADD ? CAT : breed.animalKind)
    const language = getLanguageShortName(i18n.language);
    const [images, setImages] = useState([]);
    const [updateImage, setUpdateImage] = useState(false)
    const [sourceLinks, setSourceLinks] = useState([]);
    const [breedFacts, setBreedFacts] = useState([]);
    const [disabledSubmit, setDisabledSubmit] = useState(false);
    const [countries, setCountries] = useState([]);
    const [breedEnums, setBreedEnums] = useState([]);
    const sourceIds = useRef(action === ACTION_ADD ? getEntityNextId(sourceLinks) : 0);
    const factsIds = useRef(action === ACTION_ADD ? getEntityNextId(breedFacts) : 0);

    /**
     * Инит полей формы при добавлении породы
     * просечивает данные из стора для простых полей (не массивов)
     */
    function initAdd() {
        setValue(BREED_ID, getStoredItem(BREED_ID, NUMBER))
        setValue(BREED_CODE, getStoredItem(BREED_CODE, STRING))
        setValue(BREED_TRANSLATION_ID, getStoredItem(BREED_TRANSLATION_ID, NUMBER))
        setValue(BREED_APPEARANCE_ID, getStoredItem(BREED_APPEARANCE_ID, NUMBER))
        setValue(HEALTH_ID, getStoredItem(HEALTH_ID, NUMBER))
        setValue(BREED_TITLE, getStoredItem(BREED_TITLE, STRING))
        setValue(BREED_HISTORY, getStoredItem(BREED_HISTORY, STRING))
        //MALE
        setValue(MALE_SIZE_RANGE_ID, getStoredItem(MALE_SIZE_RANGE_ID, NUMBER))
        setValue(MALE_SIZE_FROM, getStoredItem(MALE_SIZE_FROM, NUMBER))
        setValue(MALE_SIZE_TO, getStoredItem(MALE_SIZE_TO, NUMBER))

        setValue(MALE_WEIGHT_RANGE_ID, getStoredItem(MALE_WEIGHT_RANGE_ID, NUMBER))
        setValue(MALE_WEIGHT_FROM, getStoredItem(MALE_WEIGHT_FROM, NUMBER))
        setValue(MALE_WEIGHT_TO, getStoredItem(MALE_WEIGHT_TO, NUMBER))

        setValue(MALE_LIFE_RANGE_ID, getStoredItem(MALE_LIFE_RANGE_ID, NUMBER))
        setValue(MALE_LIFE_FROM, getStoredItem(MALE_LIFE_FROM, NUMBER))
        setValue(MALE_LIFE_TO, getStoredItem(MALE_LIFE_TO, NUMBER))
        //FEMALE
        setValue(FEMALE_SIZE_RANGE_ID, getStoredItem(FEMALE_SIZE_RANGE_ID, NUMBER))
        setValue(FEMALE_SIZE_FROM, getStoredItem(FEMALE_SIZE_FROM, NUMBER))
        setValue(FEMALE_SIZE_TO, getStoredItem(FEMALE_SIZE_TO, NUMBER))

        setValue(FEMALE_WEIGHT_RANGE_ID, getStoredItem(FEMALE_WEIGHT_RANGE_ID, NUMBER))
        setValue(FEMALE_WEIGHT_FROM, getStoredItem(FEMALE_WEIGHT_FROM, NUMBER))
        setValue(FEMALE_WEIGHT_TO, getStoredItem(FEMALE_WEIGHT_TO, NUMBER))

        setValue(FEMALE_LIFE_RANGE_ID, getStoredItem(FEMALE_LIFE_RANGE_ID, NUMBER))
        setValue(FEMALE_LIFE_FROM, getStoredItem(FEMALE_LIFE_FROM, NUMBER))
        setValue(FEMALE_LIFE_TO, getStoredItem(FEMALE_LIFE_TO, NUMBER))

        //CARE
        setValue(BREED_CARE_ID, getStoredItem(BREED_CARE_ID, NUMBER))
        setValue(CARE, getStoredItem(CARE, STRING))
        setValue(CARE_LEVEL, getStoredItem(CARE_LEVEL, STRING))
        setValue(CARE_GROOMING, getStoredItem(CARE_GROOMING, STRING))
        setValue(CARE_BATHING, getStoredItem(CARE_BATHING, STRING))
        setValue(CARE_EAR_CLEANING, getStoredItem(CARE_EAR_CLEANING, STRING))
        setValue(CARE_EYE_CLEANING, getStoredItem(CARE_EYE_CLEANING, STRING))
        setValue(CARE_TEETH_CLEANING, getStoredItem(CARE_TEETH_CLEANING, STRING))


        setValue(BEHAVIOUR, getStoredItem(BEHAVIOUR, STRING))
        //set isTechnical field
        setValue(BREED_IS_TECHNICAL, getStoredItem(BREED_IS_TECHNICAL, BOOLEAN))

        setBreedFacts(getStoredFacts(setValue));
        setSourceLinks(getStoredSources(setValue))
    }

    /**
     * Инит полей формы при обновлении породы
     * просечивает данные из БД
     */
    function initUpdate(breedInfo) {
        if (breedInfo === undefined) {
            return;
        }
        //заполнение объекта формы
        setValue(BREED_ID, breedInfo.id)
        setValue(BREED_CODE, breedInfo.code)
        setValue(BREED_TRANSLATION_ID, breedInfo.translation.id)
        setValue(BREED_APPEARANCE_ID, breed.appearance?.id)
        setValue(HEALTH_ID, breed.health?.id)
        setValue(BREED_TITLE, breedInfo.translation.title)
        setValue(BREED_HISTORY, breedInfo.translation.breedHistory)
        //MALE
        setValue(MALE_SIZE_RANGE_ID, breedInfo.appearance?.maleSize?.id)
        setValue(MALE_SIZE_FROM, breedInfo.appearance?.maleSize?.from)
        setValue(MALE_SIZE_TO, breedInfo.appearance?.maleSize?.to)

        setValue(MALE_WEIGHT_RANGE_ID, breedInfo.appearance?.maleWeight?.id)
        setValue(MALE_WEIGHT_FROM, breedInfo.appearance?.maleWeight?.from)
        setValue(MALE_WEIGHT_TO, breedInfo.appearance?.maleWeight?.to)

        setValue(MALE_LIFE_RANGE_ID, breedInfo.health?.maleAge?.id)
        setValue(MALE_LIFE_FROM, breedInfo.health?.maleAge?.from)
        setValue(MALE_LIFE_TO, breedInfo.health?.maleAge?.to)
        //FEMALE
        setValue(FEMALE_SIZE_RANGE_ID, breedInfo.appearance?.femaleSize?.id)
        setValue(FEMALE_SIZE_FROM, breedInfo.appearance?.femaleSize?.from)
        setValue(FEMALE_SIZE_TO, breedInfo.appearance?.femaleSize?.to)

        setValue(FEMALE_WEIGHT_RANGE_ID, breedInfo.appearance?.femaleWeight?.id)
        setValue(FEMALE_WEIGHT_FROM, breedInfo.appearance?.femaleWeight?.from)
        setValue(FEMALE_WEIGHT_TO, breedInfo.appearance?.femaleWeight?.to)

        setValue(FEMALE_LIFE_RANGE_ID, breedInfo.health?.femaleAge?.id)
        setValue(FEMALE_LIFE_FROM, breedInfo.health?.femaleAge?.from)
        setValue(FEMALE_LIFE_TO, breedInfo.health?.femaleAge?.to)

        setValue(BREED_CARE_ID, breedInfo.breedCare?.id)
        setValue(CARE, breedInfo.translation.care)
        setValue(CARE_LEVEL, breedInfo.breedCare?.careLevel?.id)
        setValue(CARE_GROOMING, breedInfo.breedCare?.groomingFrequency?.id)
        setValue(CARE_BATHING, breedInfo.breedCare?.bathingFrequency?.id)
        setValue(CARE_EAR_CLEANING, breedInfo.breedCare?.earCleaningFrequency?.id)
        setValue(CARE_EYE_CLEANING, breedInfo.breedCare?.eyeCleaningFrequency?.id)
        setValue(CARE_TEETH_CLEANING, breedInfo.breedCare?.teethCleaningFrequency?.id)


        setValue(BEHAVIOUR, breedInfo.translation.behaviour)
        setValue(SOURCES, breedInfo.translation.sources)
        setValue(BREED_FACTS, breedInfo.translation.breedFacts)
        setValue(BREED_IS_TECHNICAL, breedInfo.technical)

        //заполнение коллекций
        initFacts(factsIds, setBreedFacts, breedInfo.translation.breedFacts)
        initSourceLinks(sourceIds, setSourceLinks, breedInfo.translation.sources);
        if (image.data_url !== EMPTY) {
            setImages(Array.of(image))
        }

        if (hasValue(breedInfo.country)) {
            storeItem(BREED_COUNTRY_ID, breedInfo.country.id)
        }
    }

    /**
     * Получить список стран
     */
    function getCountriesOptions() {
        const response = getDictionaryRecordsAsOptions(COUNTRY_METADATA.backControllerName);
        response.then((resp) => {
            setErrorMessage(EMPTY)
            setCountries(resp.data.data)
        }, (error) => {
            setErrorMessage(error.response?.data?.messages ?
                error.response?.data?.messages?.ERROR[0] : error.message)
        });
    }

    function getEnumsValues() {
        const response = getDictionaryEnums(BREED_METADATA.backControllerName);
        response.then((resp) => {
            setErrorMessage(EMPTY)
            setBreedEnums(resp.data.data)
        }, (error) => {
            setErrorMessage(error.response?.data?.messages ?
                error.response?.data?.messages?.ERROR[0] : error.message)
        });
    }

    /**
     * Получить первоначальное значение id в селект со списком
     */
    function getCountryInitialId() {
        const storedValue = getStoredItem(BREED_COUNTRY_ID, NUMBER);
        if (storedValue !== 0) {
            return storedValue;
        }

        return ACTION_ADD === action || !breedInfo || !breedInfo.country ?
            0 : breedInfo.country.id;
    }

    /**
     * Обработка события отправки формы
     */
    function submitForm(data) {
        setDisabledSubmit(true);
        setIsLoading(true)

        const dto = formDto(data, language, animalKind);

        // при обновлении данных о породе, чтобы не пересохранять данные об изображении
        // мы проверяем обновлялась ли она на UI
        if (updateImage === false && action === ACTION_UPDATE) {
            dto.filename = breed.filename;
        }

        const resp = BreedService.saveBreed(
            dto,
            updateImage,
            images.length === 0 ? null : images[0].file,
            token)
        resp.then(
            (resp) => {
                clearSessionDataForEntity(BREED_METADATA.fields)
                setError(false);
                setIsLoading(false);
                navigate(`/breeds/${resp.data.data.breedId}`);
            },
            (error) => {
                setIsLoading(false)
                setError(true)
                setDisabledSubmit(false);
                setErrorMessage(error.response?.data?.messages ?
                    error.response?.data?.messages?.ERROR[0] : error.message)
            })
    }

    useEffect(() => {
        //получаем countries
        getCountriesOptions();
        //получаем все значения Enum'ов
        getEnumsValues();
        switch (action) {
            case ACTION_ADD :
                return initAdd();
            case ACTION_UPDATE :
                return initUpdate(breed);
            default:
                return;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function onImageChange(imageList) {
        setUpdateImage(true);
        setImages(imageList);
    }

    const allValues = watch();

    return (
        <Form handleSubmit={handleSubmit} submitForm={submitForm}>
            <HiddenInput fieldName={BREED_ID} register={register}/>
            <HiddenInput fieldName={BREED_TRANSLATION_ID} register={register}/>
            <HiddenInput fieldName={BREED_APPEARANCE_ID} register={register}/>
            <HiddenInput fieldName={HEALTH_ID} register={register}/>
            {/*<TranslationLanguageSelector language={language} setLanguage={setLanguage}/>*/}
            <FormItem>
                <AnimalKindSelector animalKind={animalKind} setAnimalKind={setAnimalKind}
                                    catTitle={t("form.newBreed.cat")} dogTitle={t("form.newBreed.dog")}/>
            </FormItem>

            <TextInputFormItem errors={errors} fieldName={BREED_TITLE} placeholder={t("form.newBreed.breedTitle")}
                               register={register} hasValue={hasValue(allValues[BREED_TITLE])}
                               tabIndex='1' onBlurFunction={action === ACTION_ADD ? storeItem : null}/>

            <TextInputFormItem errors={errors} fieldName={BREED_CODE} placeholder='C_CODE'
                               register={register} hasValue={hasValue(allValues[BREED_CODE])}
                               tabIndex='2' onBlurFunction={action === ACTION_ADD ? storeItem : null}/>

            <TextInputWithOptionsFormItem fieldName={BREED_COUNTRY_ID} options={countries}
                                          placeholder='Страна происхождения'
                                          register={register} tabIndex='3' setValue={setValue}
                                          initialId={getCountryInitialId()}/>

            <FormImage images={images} maxNumber={FILES_MAX_NUMBER} onChange={onImageChange}/>

            <TextAreaFormItem fieldName={BREED_HISTORY} placeholder={t("form.newBreed.breedHistory")}
                              register={register} tabIndex='4' action={action}
                              hasValue={allValues[BREED_HISTORY]}/>

            <div className="breed-section__wrapper">
                <div className="breed-section">
                    <div className="breed-section__header">
                        <h2>Уход</h2>
                    </div>
                    {hasValue(breedEnums?.careEnums) ?
                        <>
                            <div className="breed-section__aggregated-mark">
                                <RadioSet register={register} fieldName={CARE_LEVEL} radioSetTitle="Общая оценка"
                                          options={breedEnums.careEnums[CARE_LEVEL]} required={true} needToStore={true}
                                          additionalClasses="wide"/>
                            </div>
                            <div className="breed-section__extended_params">
                                <RadioSet register={register} fieldName={CARE_GROOMING}
                                          radioSetTitle={t("page.breed.section.care.grooming")}
                                          options={breedEnums.careEnums?.grooming} required={false} needToStore={true}/>
                                <RadioSet register={register} fieldName={CARE_BATHING}
                                          radioSetTitle={t("page.breed.section.care.bathing")}
                                          options={breedEnums.careEnums?.bathing} required={false} needToStore={true}/>
                                <RadioSet register={register} fieldName={CARE_EAR_CLEANING}
                                          radioSetTitle={t("page.breed.section.care.earCleaning")}
                                          options={breedEnums.careEnums?.earCleaning} required={false} needToStore={true}/>
                                <RadioSet register={register} fieldName={CARE_EYE_CLEANING}
                                          radioSetTitle={t("page.breed.section.care.eyeCleaning")}
                                          options={breedEnums.careEnums?.eyeCleaning} required={false} needToStore={true}/>
                                <RadioSet register={register} fieldName={CARE_TEETH_CLEANING}
                                          radioSetTitle={t("page.breed.section.care.teethCleaning")}
                                          options={breedEnums.careEnums?.teethCleaning} required={false} needToStore={true}/>
                            </div>
                        </> :
                        null
                    }

                    <div className="some-other-information">
                        <TextAreaFormItem fieldName={CARE} placeholder={t("form.newBreed.careDescription")}
                                          register={register} tabIndex='17' action={action}
                                          hasValue={isFieldFilled(getValues, CARE)}/>
                    </div>
                </div>
            </div>

            <BreedGenderParams allValues={allValues} action={action} register={register} errors={errors}/>


            <TextAreaFormItem fieldName={BEHAVIOUR} placeholder={t("form.newBreed.behaviour")}
                              register={register} tabIndex='18' action={action}
                              hasValue={isFieldFilled(getValues, BEHAVIOUR)}/>

            <BreedFactsFormElement factsIds={factsIds} breedFacts={breedFacts} setBreedFacts={setBreedFacts}
                                   action={action} errors={errors} register={register} getValues={getValues}
                                   setValue={setValue}/>

            <BreedSourceLinksFormElement sourceIds={sourceIds} sourceLinks={sourceLinks} setSourceLinks={setSourceLinks}
                                         register={register} errors={errors} action={action} getValues={getValues}
                                         setValue={setValue}/>

            {/*Видимость в списках*/}
            <CheckboxFormItem fieldName={BREED_IS_TECHNICAL} register={register}
                              checkboxValue={getValues(BREED_IS_TECHNICAL)}
                              putValueInStore={ACTION_ADD === action} getValues={getValues}
                              title={t("form.newBreed.isTechnical")} tabIndex='19' errors={errors}
                              additionalStyle='as-table-header'/>

            <SubmitSection hasFormError={error} errorMessage={errorMessage} isFormLoading={loading}
                           submitLabel={t("button.save")} isSubmitDisabled={disabledSubmit}/>
        </Form>
    );
};

export default BreedForm;