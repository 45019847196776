//Значение по умолчанию (используется в селектбоксах)
export const DEFAULT = 'default';
export const EMPTY = '';

export const MIXED_BREED_TITLE = 'Метис (смешанная порода)';
export const NO_BREED_TITLE = 'БЕЗ ПОРОДЫ';
export const DEFAULT_PAGE_LIMIT = 50;
export const CAT = 'CAT';
export const DOG = 'DOG';
export const ANIMAL_KIND_STORED_ITEM = 'animalKindStoredItem';
export const availableAnimalKinds = [CAT, DOG]

export function getLocalizedAnimalKinds(t) {
    return [
        {value: CAT, name: t("animalKinds.cat")},
        {value: DOG, name: t("animalKinds.dog")}
    ];
}
