import React, {useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMars} from "@fortawesome/free-solid-svg-icons/faMars";
import {faVenus} from "@fortawesome/free-solid-svg-icons/faVenus";
import {isEmptyOrNull} from "../../app/helper/commonHelper";
import config from "../../app.config.json";
import {EMPTY} from "../../app/const/appConst";
import {useTranslation} from "react-i18next";
import {RANGE_TYPE_AGE, RANGE_TYPE_SIZE, RANGE_TYPE_WEIGHT} from "../../app/const/rangeConst";

/**
 * Гендерно зависимые параметры: рост, вес, продолжительность жизни
 */
const GenderCharacteristics = ({genderParams}) => {
    const {t} = useTranslation();
    const MALE = 'male'
    const FEMALE = 'female'
    const ACTIVE_CLASS = 'active'
    const XL_SIZE = 'xl'
    const CAKE_IMAGE = 'cake.png'
    const MEASURE_IMAGE = 'measure.png'
    const WEIGHT_IMAGE = 'weight.png'
    const [currentGender, setCurrentGender] = useState(MALE);

    function getIconName(title) {
        switch (title) {
            case RANGE_TYPE_WEIGHT:
                return WEIGHT_IMAGE
            case RANGE_TYPE_SIZE:
                return MEASURE_IMAGE
            case RANGE_TYPE_AGE:
                return CAKE_IMAGE
            default:
                return EMPTY;
        }
    }

    function getMeasureTranslation(measure) {
        return t('measure.' + measure.toString().toLowerCase());
    }

    if (isEmptyOrNull(genderParams?.male) || isEmptyOrNull(genderParams?.female)) {
        return null;
    }

    return (
        <div className="gender-params__wrapper">
            <div className="gender-params__header">
                <div className={`gender-params__header-icon male ${currentGender === MALE ? ACTIVE_CLASS : EMPTY}`}
                     onClick={() => setCurrentGender(MALE)}>
                    <FontAwesomeIcon icon={faMars} size={XL_SIZE}/>
                </div>
                <div className={`gender-params__header-icon female ${currentGender === FEMALE ? ACTIVE_CLASS : EMPTY}`}
                     onClick={() => setCurrentGender(FEMALE)}>
                    <FontAwesomeIcon icon={faVenus} size={XL_SIZE}/>
                </div>
            </div>
            <div className="gender-params__params-section">
                {genderParams[currentGender].map((el, index) => {
                    return <div key={index} className="gender-params__param">
                        <div className="gender-params__param-icon">
                            <img src={config.imagesPath.icons + getIconName(el.title)} alt={EMPTY}/>
                        </div>
                        <div className="gender-params__param-data">
                            <span>{`${el.data.from} — ${el.data.to} ${getMeasureTranslation(el.data.measure)}`}</span>
                        </div>
                    </div>
                })}
            </div>
        </div>
    );
};

export default GenderCharacteristics;
