import React from 'react';
import Content from "../../components/common/Content";
import TelegramLink from "./TelegramLink";

/**
 * Контент главной страницы десктопной версии для зарегистрированного пользователя
 */
const DesktopIndexContentAuthUser = () => {
    return (
        <Content>
            {/*{t("common.comingSoon")}*/}
            <TelegramLink/>
        </Content>
    );
};

export default DesktopIndexContentAuthUser;