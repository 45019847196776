/**
 * Сервис по работе с кэшем
 */

import axios from "axios";
import {LOCAL} from "../app/const/main";

/**
 *  Сбросить кэш справочников
 */
export async function resetDictionaryCache(token) {
    return await axios.post(`${LOCAL}/cache/reset-dictionary-cache`, null,
        {
            headers: {
                'Authorization': 'Bearer_' + token
            }
        });
}