import React, {useState} from 'react';
import {HIDDEN, SINGLE_OPTION, STRING} from "../../../../../components/common/form/helper/formConstants";
import {
    clearSessionDataForEntity,
    isFieldFilled,
    storeItem
} from "../../../../../components/common/form/helper/formHelper";
import TextInputFormItem from "../../../../../components/common/form/parts/inputs/wrappers/TextInputFormItem";
import HiddenInput from "../../../../../components/common/form/parts/inputs/HiddenInput";
import TextInputWithOptionsFormItem
    from "../../../../../components/common/form/parts/inputs/wrappers/TextInputWithOptionsFormItem";
import {saveRecord} from "../../../../../service/dictionaryService";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import SubmitSection from "../../../../../components/common/form/parts/controlsection/SubmitSection";
import {getLanguageShortName} from "../../../../../utils/langUtils";
import {useTranslation} from "react-i18next";
import Form from "../../../../../components/common/form/abstract/Form";

/**
 * Компонент, который строит инпуты формы по метамодели справочника
 */
const SimpleDictionaryForm = ({
                                  register,
                                  getValues,
                                  setValue,
                                  handleSubmit,
                                  errors,
                                  metadata,
                                  loading,
                                  setIsLoading,
                                  errorMessage,
                                  setErrorMessage,
                                  formDto
                              }) => {
        const navigate = useNavigate();
        const {t, i18n} = useTranslation();
        const token = useSelector(state => state.userReducer.token);
        const [error, setError] = useState(false);
        const [disabledSubmit, setDisabledSubmit] = useState(false);

        const language = getLanguageShortName(i18n.language);

        /**
         * Обработка события отправки формы
         */
        function submitForm(data) {
            setDisabledSubmit(true);
            setIsLoading(true)
            const dto = formDto(data, language);
            const resp = saveRecord(dto, metadata.backControllerName, token)
            resp.then(
                (resp) => {
                    clearSessionDataForEntity(metadata.fields);
                    setError(false);
                    setIsLoading(false);
                    navigate(metadata.navigate);
                },
                (error) => {
                    setIsLoading(false)
                    setError(true)
                    setDisabledSubmit(false);
                    setErrorMessage(error.response?.data?.messages ?
                        error.response?.data?.messages?.ERROR[0] : error.message)
                })
        }


        return (
            <Form handleSubmit={handleSubmit} submitForm={submitForm}>
                {metadata.fields.map((field, index) => {
                    switch (field.type) {
                        case HIDDEN:
                            return (<HiddenInput key={index} fieldName={field.name} register={register}/>);
                        case STRING:
                            return (<TextInputFormItem key={index} errors={errors} fieldName={field.name}
                                                       placeholder={field.placeholder}
                                                       register={register} hasValue={isFieldFilled(getValues, field.name)}
                                                       tabIndex={field.tabIndex} onBlurFunction={storeItem}/>);
                        case SINGLE_OPTION:
                            return (<TextInputWithOptionsFormItem key={index} fieldName={field.name}
                                                                  options={field.options}
                                                                  placeholder={field.placeholder}
                                                                  register={register}
                                                                  setValue={setValue}
                                                                  tabIndex={field.tabIndex}
                                                                  initialId={field.initialId}/>);
                        default:
                                return <></>;
                    }
                })
                }
                <SubmitSection hasFormError={error} errorMessage={errorMessage} isFormLoading={loading}
                               submitLabel={t("button.save")} isSubmitDisabled={disabledSubmit}/>
            </Form>
        );
    }
;

export default SimpleDictionaryForm;