import React, {useEffect, useState} from 'react';
import {PET_BREED} from "../../../newpet/helpers/petFormConstants";
import TextInputWithOptionsFormItem
    from "../../../../../components/common/form/parts/inputs/wrappers/TextInputWithOptionsFormItem";
import {useTranslation} from "react-i18next";
import {showModal} from "../../../../../app/store/modalReducer";
import {useDispatch} from "react-redux";
import {EMPTY, MIXED_BREED_TITLE, NO_BREED_TITLE} from "../../../../../app/const/appConst";
import {isEmptyOrNull} from "../../../../../app/helper/commonHelper";
import ChoosingBreedTip from "./ChoosingBreedTip";

/**
 * Компонент формы "Порода питомца"
 */
const PetBreedFormItem = ({
                              breeds, register, setValue, errors,
                              tabIndex = 1, initialId = 0, disabled = false,
                              isNeedToStore = true
                          }) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [breedInitialId, setBreedInitialId] = useState(initialId);

    /**
     * При обновлении ИД породы извне компонента обновлять управляемый элемент
     */
    useEffect(() => {
        setBreedInitialId(initialId)
    }, [initialId])

    /**
     * Получить идентификатор смешанной породы
     */
    function getMetisBreedId() {
        if (isEmptyOrNull(breeds)) {
            return 0;
        }
        const mixedBreed = breeds.filter(breed => breed.translation.title.toLowerCase().includes(MIXED_BREED_TITLE.toLowerCase()));
        if (isEmptyOrNull(mixedBreed)) {
            return 0;
        }
        return mixedBreed[0].id;
    }

    /**
     * Получить идентификатор технической породы: без породы
     */
    function getNoBreedId() {
        if (isEmptyOrNull(breeds)) {
            return 0;
        }
        const mixedBreed = breeds.filter(breed => breed.translation.title.toLowerCase().includes(NO_BREED_TITLE.toLowerCase()));
        if (isEmptyOrNull(mixedBreed)) {
            return 0;
        }
        return mixedBreed[0].id;
    }

    function showTip() {
        const mixedBreedId = getMetisBreedId();
        const noBreedId = getNoBreedId();

        dispatch(showModal({
            popup: {
                title: EMPTY,
                body: <ChoosingBreedTip mixedBreedId={mixedBreedId} noBreedId={noBreedId}
                                        setInitialBreedId={setBreedInitialId}/>
            }
        }))
    }


    return (
        <div className="breed-input">
            <TextInputWithOptionsFormItem fieldName={PET_BREED} options={breeds} placeholder={t('page.breed.title')}
                                          register={register} setValue={setValue} tabIndex={tabIndex}
                                          initialId={breedInitialId} isNeedToStore={isNeedToStore} disabled={disabled}
                                          errors={errors}/>
            <div className="breed-input__help-link">
                <span className="link small" onClick={(e) => showTip()}>
                    {t('page.breed.needHelpWithBreed')}
                </span>
            </div>
        </div>
    );
};

export default PetBreedFormItem;