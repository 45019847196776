import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {formCountryDto, formCountrySchema} from "../geoHelper";
import {
    ACTION_ADD,
    ACTION_UPDATE,
    HIDDEN,
    NUMBER,
    SINGLE_OPTION,
    STRING
} from "../../../../../components/common/form/helper/formConstants";
import {
    CONTINENTS_METADATA,
    COUNTRY_ALPHA2_CODE,
    COUNTRY_ALPHA3_CODE, COUNTRY_CODE,
    COUNTRY_CONTINENT_ID,
    COUNTRY_ID,
    COUNTRY_ISO_CODE,
    COUNTRY_TRANSLATION_ID,
    COUNTRY_TRANSLATION_TITLE
} from "../geoFormsConstants";
import {getAllRecordsInCurrentTranslation} from "../../../../../service/dictionaryService";
import {getStoredItem, storeItem} from "../../../../../components/common/form/helper/formHelper";
import {isEmptyOrNull} from "../../../../../app/helper/commonHelper";
import {EMPTY} from "../../../../../app/const/appConst";
import SimpleDictionaryForm from "./SimpleDictionaryForm";

/**
 * Форма добавления страны
 * action = ADD или UPDATE - чтобы разделить логику форм
 */
const CountryForm = ({action, country = null}) => {
    const {t, i18n} = useTranslation();
    const [errorMessage, setErrorMessage] = useState(EMPTY);
    const [loading, setIsLoading] = useState(false);


    const [continents, setContinents] = useState();

    const {
        register,
        formState: {errors},
        handleSubmit,
        setValue,
        getValues
    } = useForm({
        resolver: yupResolver(formCountrySchema(t)),
        mode: "onBlur",
        reValidateMode: "onBlur"
    })

    /**
     * Мета информация о справочнике
     * title - название справочника
     * backControllerName - используется для построения пути при сохранении/обновлении справочника
     * titlePrepositionalCase - название справочника в предложном падеже "о ком? о чём?"
     * navigate - куда редиректить после сохранения формы
     * fields - массив полей формы
     */
    const COUNTRY_METADATA_TEST = {
        title: 'adminPanel.dictionaries.countries.title',
        backControllerName: 'countries',
        titlePrepositionalCase: 'adminPanel.dictionaries.countries.titlePrepositionalCase',
        navigate: '/panel/dictionaries/countries',
        fields: [
            {
                name: COUNTRY_ID,
                type: HIDDEN
            },
            {
                name: COUNTRY_TRANSLATION_ID,
                type: HIDDEN
            },
            {
                name: COUNTRY_TRANSLATION_TITLE,
                type: STRING,
                placeholder: 'Название',
                tabIndex: 1
            },
            {
                name: COUNTRY_CODE,
                type: STRING,
                placeholder: 'C_CODE',
                tabIndex: 2
            },
            {
                name: COUNTRY_ALPHA2_CODE,
                type: STRING,
                placeholder: 'Alpha2',
                tabIndex: 3
            },
            {
                name: COUNTRY_ALPHA3_CODE,
                type: STRING,
                placeholder: 'Alpha3',
                tabIndex: 4
            },
            {
                name: COUNTRY_ISO_CODE,
                type: STRING,
                placeholder: 'ISO',
                tabIndex: 5
            },

            {
                name: COUNTRY_CONTINENT_ID,
                type: SINGLE_OPTION,
                options: continents,
                initialId: getContinentInitialId(),
                placeholder: 'Континент',
                tabIndex: 5
            }
        ]
    }

    /**
     * Получаем значения континентов
     */
    function getContinents() {
        setIsLoading(true);
        const response = getAllRecordsInCurrentTranslation(CONTINENTS_METADATA.backControllerName);
        response.then((resp) => {
            setErrorMessage(EMPTY)
            setContinents(resp.data.data)
            setIsLoading(false)
        }, (error) => {
            setErrorMessage(error.response?.data?.messages ?
                error.response?.data?.messages?.ERROR[0] : error.message)
            setIsLoading(false)
        });
    }

    /**
     * Инит полей формы при добавлении страны
     * просечивает данные из стора для простых полей (не массивов)
     */
    function initAdd() {
        setValue(COUNTRY_ID, getStoredItem(COUNTRY_ID, NUMBER))
        setValue(COUNTRY_ALPHA2_CODE, getStoredItem(COUNTRY_ALPHA2_CODE, STRING))
        setValue(COUNTRY_ALPHA3_CODE, getStoredItem(COUNTRY_ALPHA3_CODE, STRING))
        setValue(COUNTRY_ISO_CODE, getStoredItem(COUNTRY_ISO_CODE, STRING))

        setValue(COUNTRY_TRANSLATION_ID, getStoredItem(COUNTRY_TRANSLATION_ID, NUMBER))
        setValue(COUNTRY_TRANSLATION_TITLE, getStoredItem(COUNTRY_TRANSLATION_TITLE, STRING))
        setValue(COUNTRY_CODE, getStoredItem(COUNTRY_CODE, STRING))

    }

    /**
     * Инит полей формы при обновлении страны
     * просечивает данные из БД
     */
    function initUpdate(country) {
        if (isEmptyOrNull(country)) {
            return;
        }
        setValue(COUNTRY_ID, country.id)
        setValue(COUNTRY_ALPHA2_CODE, country.alpha2Code)
        setValue(COUNTRY_ALPHA3_CODE, country.alpha3Code)
        setValue(COUNTRY_ISO_CODE, country.isoCode)

        setValue(COUNTRY_TRANSLATION_ID, country.translation.id)
        setValue(COUNTRY_TRANSLATION_TITLE, country.translation.title)
        setValue(COUNTRY_CODE, country.code)
        setValue(COUNTRY_CONTINENT_ID, country.continent.id)

        // Для селекта с опциями значение, которое мы будем отправлять на бэк мы берем из стора
        storeItem(COUNTRY_CONTINENT_ID, country.continent.id);
    }

    /**
     * Получить первоначальное значение id в селект со списком
     */
    function getContinentInitialId() {
        const storedValue = getStoredItem(COUNTRY_CONTINENT_ID, NUMBER);
        if (storedValue !== 0) {
            return storedValue;
        }

        return ACTION_ADD === action || !country || !country.continent ?
            0 : country.continent.id;
    }

    // просечиваем все поля в форму, когда continents загружены
    useEffect(() => {
        //получаем continents
        getContinents();

        switch (action) {
            case ACTION_ADD :
                return initAdd();
            case ACTION_UPDATE :
                return initUpdate(country);
            default:
                return;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n.language, country]);

    return (
        <SimpleDictionaryForm register={register} getValues={getValues} setValue={setValue} handleSubmit={handleSubmit}
                              errors={errors} metadata={COUNTRY_METADATA_TEST} loading={loading} setIsLoading={setIsLoading}
                              errorMessage={errorMessage} setErrorMessage={setErrorMessage} formDto={formCountryDto}/>
    );
};

export default CountryForm;