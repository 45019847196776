import React from 'react';

/**
 * Ссылка на телеграм-канал
 */
const TelegramLink = () => {
    return (
        <div className="milkins-telegram-link__wrapper" onClick={() => window.open("https://t.me/milkinsPet", "_blank")}>
            <div className="telegram-logo">
                <img src="/images/icons/telegram.png" height="32px" width="32px" alt=""/>
            </div>
            <span>Новости проекта</span>
        </div>
    );
};

export default TelegramLink;