/**
 * Хэлпер по работе с изображениями
 */


/**
 * Сформировать картинку из blob
 *
 * @param blob (Binary Large Object) представляет собой объект, который содержит сырые данные в бинарном формате.
 * Blob позволяет работать с файловыми объектами и данными, таким как изображения, видео, аудиофайлы
 * и другие типы данных, которые могут быть загружены или загружены в браузере.
 *
 * @param contentDispositionHeader  для указания способов обработки содержимого тела ответа или тела сообщения
 */
export function createImageFromBlob(blob, contentDispositionHeader) {
    let header = contentDispositionHeader;
    const myBlob = blob;
    //получаем имя файла
    myBlob.name = header.substring(header.indexOf('=') + 1)
    //записываем время последней модификации
    myBlob.lastModified = new Date();

    const fileImage = new File([myBlob], myBlob.name, {
        type: myBlob.type
    });
    //создаем URL, чтобы можно было использовать в <img>
    return URL.createObjectURL(fileImage);
}