import React from 'react';

/**
 * Заголовок таблицы
 */
const TableTitle = ({title}) => {
    return (
        <div className="table-line table-title">
            {title.map((cell, index) => (
                <div key={index} className={`table-line__cell cell-${cell.size}`}>
                    <span className="table-line__description">{cell.name}</span>
                </div>
            ))}
        </div>
    );
};

export default TableTitle;