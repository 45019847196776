import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {EMPTY} from "../../../../app/const/appConst";
import {useSelector} from "react-redux";
import {getAllRecords, getRecordsCount, getRecordsPerPage} from "./tableService";
import {getPageCount} from "../../../../app/helper/commonHelper";
import Spinner from "../../Spinner";
import TableNoResults from "../TableNoResults";
import SimpleTable from "../simple/SimpleTable";
import TableTitle from "./TableTitle";
import Pagination from "../../form/pagination/Pagination";
import TableLines from "./TableLines";

/**
 * Гибкий компонент таблицы
 */
const Table = ({entityMetadata}) => {
    const {t} = useTranslation()
    const [errorMessage, setErrorMessage] = useState(EMPTY);
    const [loading, setIsLoading] = useState(true);
    const [entitiesCount, setEntitiesCount] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1)
    const [entities, setEntities] = useState([]);
    const token = useSelector(state => state.userReducer.token);

    useEffect(() => {
        window.scrollTo({top: 0, left: 0});
        getRecordsTotalCount(entityMetadata.entity);
    }, [])

    /**
     * Для пагинации подгружаем данные при смене текущей страницы
     */
    useEffect(() => {
        if (entityMetadata.needPagination && pageCount > 0) {
            getRecordsByPage(currentPage);
        }
    }, [pageCount, currentPage])

    /**
     * Если пагинация не нужна - загружаем полный список сущностей
     */
    useEffect(() => {
        if (!entityMetadata.needPagination) {
            getAllEntityRecords();
        }
    }, [])

    /**
     * Получаем общее количество записей
     */
    function getRecordsTotalCount(entity) {
        const response = getRecordsCount(entity, token);
        response.then((resp) => {
            setErrorMessage(EMPTY)
            const responseData = resp.data.data;
            setEntitiesCount(responseData)
            setPageCount(getPageCount(responseData))
            setIsLoading(false)
        }, (error) => {
            setErrorMessage(error.response?.data?.messages ?
                error.response?.data?.messages?.ERROR[0] : error.message)
            setIsLoading(false)
        });
    }

    /**
     * Получаем записи для конкретной страницы
     */
    function getRecordsByPage(page) {
        const response = getRecordsPerPage(entityMetadata.entity, page, token);
        response.then((resp) => {
            setErrorMessage(EMPTY)
            setEntities(resp.data.data);
            setIsLoading(false)
        }, (error) => {
            setErrorMessage(error.response?.data?.messages ?
                error.response?.data?.messages?.ERROR[0] : error.message)
            setIsLoading(false)
        });
    }

    /**
     * Получаем все записи
     */
    function getAllEntityRecords() {
        const response = getAllRecords(entityMetadata.entity, token);
        response.then((resp) => {
            setErrorMessage(EMPTY)
            setEntities(resp.data.data);
            setIsLoading(false)
        }, (error) => {
            setErrorMessage(error.response?.data?.messages ?
                error.response?.data?.messages?.ERROR[0] : error.message)
            setIsLoading(false)
        });
    }


    return (
        <>
            <div className="admin-panel total-count-of-elements">
                <span>{`Всего: ${entitiesCount}`}</span>
            </div>
            {
                loading ?
                    <Spinner/> :
                    entitiesCount === 0 ?
                        <TableNoResults title={t('common.user.table.noResult')}/> :
                        <SimpleTable>
                            <TableTitle title={entityMetadata.fields}/>
                            <TableLines entities={entities} fields={entityMetadata.fields} />
                            {/*<UsersLines users={users}/>*/}
                        </SimpleTable>}
            {
                entityMetadata.needPagination ?
                    <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} pageNeighbours={1}
                                totalRecords={entitiesCount}/> :
                    null
            }

        </>
    );
};

export default Table;