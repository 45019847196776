import React from 'react';
import {useTranslation} from "react-i18next";
import {isEmptyOrNull} from "../../../../app/helper/commonHelper";

/**
 * Компонент ссылок на ресурсы, из которых была взята информация
 */
const SourceLinks = ({sources}) => {
    const {t} = useTranslation();

    function getLink(source) {
        if (!isEmptyOrNull(source.sourceLink)) {
            return <a key={source.sourceLink} href={source.sourceLink}
                      target="_blank"
                      rel="noreferrer">{source.linkDescription}</a>
        }
        return <span>{source.linkDescription}</span>
    }
    return (
        <div className="article-with-title">
            <div className="article-with-title__title source-header">
                <span>{t("page.breed.section.sources")}</span>
            </div>
            <div className="article-with-title__text sources">
                {sources?.map(source =>
                    <p key={source.borrowedObject}>
                        {source.borrowedObject + ": "}
                        {getLink(source)}
                    </p>
                )}
            </div>
        </div>
    );
};

export default SourceLinks;