/**
 * Получить общее количество записей
 */
import axios from "axios";
import {LOCAL} from "../../../../app/const/main";

export async function getRecordsCount(entity, token) {
    const config = {
        headers: {
            'Authorization': 'Bearer_' + token
        }
    }
    return await axios.get(`${LOCAL}/${entity}/count`, config);
}

/**
 * Получить записи для определенной страницы
 * page - 1 потому что на бэке страницы начинаются с нуля
 */
export async function getRecordsPerPage(entity, page, token) {
    const config = {
        headers: {
            'Authorization': 'Bearer_' + token
        }
    }
    return await axios.get(`${LOCAL}/${entity}/page/${page - 1}`, config);
}

/**
 * Получить все элементы определённой сущности
 */
export async function getAllRecords(entity, token) {
    const config = {
        headers: {
            'Authorization': 'Bearer_' + token
        }
    }
    return await axios.get(`${LOCAL}/${entity}/`, config);
}